<template>
  <div class="ui-strong-item wrap960">
    <div class="title-cover">
      <img :src="`${rootUrl}/img/title02.png`" alt="買取強化品目" />
    </div>

    <div
      class="record-box"
      v-for="(groupData, groupIndex) in categoryData.groupList"
      :key="groupIndex"
    >
      <div class="sub-title space-100">
        <img :src="`${rootUrl}/img/record03.png`" alt="レコードイラスト" />
        <span>{{ groupData.name }}</span>
      </div>
      <div class="item-box-cover">
        <template v-for="(memberData, memberIndex) in groupData.memberList">
          <div
            class="item-box"
            v-if="memberData.visible === true"
            :key="memberIndex"
          >
            <div class="square_image">
              <img
                :src="`${rootUrl}/storage/${memberData.imgPath}`"
                :alt="memberData.name"
              />
            </div>
            <div class="title">
              <p>{{ memberData.name }}</p>
            </div>
            <div class="text-cover">
              <p class="des" v-html="memberData.text"></p>
              <router-link
                :to="`${categoryData.code}/article/${memberData.articleId}`"
              >
                <img :src="`${rootUrl}/img/button02.png`" alt="もっと見る" />
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiItemBox from "@/components/Ui/ItemBox.vue";
export default {
  name: "home-strong-item-classic",
  components: {
    // UiItemBox,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-strong-item {
  .record-box {
    .sub-title {
      img {
        width: 50px;
        vertical-align: middle;
        margin-right: 10px;
      }
      span {
        font-weight: 700;
        vertical-align: middle;
        font-size: 32px;
      }
    }

    .item-box-cover {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        // flex-direction: column;
      }
      img {
        width: 100%;
      }
    }
    .item-box-cover::before {
      content: "";
      display: block;
      width: 24%;
      order: 1;
    }
    .item-box-cover::after {
      content: "";
      display: block;
      width: 24%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .item-box {
      width: 24%;
      // padding-bottom: 30px;
      margin-top: 30px;

      @media screen and (max-width: 787px) {
        width: 48%;
      }

      .square_image {
        position: relative;
        // min-height: 150px !important;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          object-fit: cover;
        }
      }
      .square_image::before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      .title {
        font-size: 18px;
        color: white;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;
        margin-top: 10px;
        line-height: 1.2em;
        min-height: 75px;
        display: flex;
        align-items: flex-start;
        p {
          min-height: 45px;
          background-color: black;
          padding: 0 15px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 768px) {
            padding: 0 10px;
          }
        }
      }
      .text-cover {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        padding: 20px 10px;
        text-align: left;

        .des {
          font-size: 18px;
          line-height: 1.6em;
          min-height: 250px;
          @media screen and (min-width: 788px) and (max-width: 950px) {
            min-height: 295px;
          }
          @media screen and (min-width: 549px) and (max-width: 787px) {
            min-height: 210px;
          }
          @media screen and (max-width: 550px) {
            min-height: 256px;
            font-size: 16px;
          }
        }

        a {
          text-decoration: none;
          padding-left: 1px;
        }
        a:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>