<template>
  <div class="classicrecord-assessment-points">
    <div class="title-cover" v-if="pageType === `classic`">
      <img :src="`${rootUrl}/img/title09.png`" alt="クラシックレコードの査定ポイント" />
    </div>
    <div class="title-cover" v-else-if="pageType === `jazz`">
      <img :src="`${rootUrl}/img/title12.png`" alt="ジャズレコードの査定ポイント" />
    </div>
    <div class="title-cover" v-else-if="pageType === `openreel`">
      <img :src="`${rootUrl}/img/title15.png`" alt="オープンリールの査定ポイント" />
    </div>
    <div class="title-cover" v-else-if="pageType === `wj`">
      <img :src="`${rootUrl}/img/title19.png`" alt="オープンリールの査定ポイント" />
    </div>
    <div v-if="pageType === `classic` || pageType === `jazz` || pageType === `wj`">
       <UiBoxPoint
        :imgpath="`classic-point01.png`"
        :name="`輸入盤イメージ 
      `"
        :title="`国内盤より輸入盤`"
        :des="`国内盤は市場の流通量の多さから、高額になる人気タイトルが限定されます。人気のタイトルは、日本独自の企画盤、高音質盤などになります。それに比べ輸入盤、特にオリジナル盤などは稀少性、音質面を含め、現在でも安定した需要があり高額買取の期待が持てます。`"
      />
       <UiBoxPoint
        :imgpath="`classic-point02.png`"
        :name="`付属品イメージ 
      `"
        :title="`付属品も確認`"
        :des="`レコードはジャケット、レコード盤の状態とは別に、付録や付属品なども重要な買取要素です。特に国内盤は、基本的に元々、帯・解説書といった付属品がついています。この付属品が有るか無いか、また状態も買取価格に大きく影響します。比較的流通しているものでも、付属品がすべて揃っている綺麗な状態なら高額になる作品も多数です。`"
      />
       <UiBoxPoint
        :imgpath="`classic-point03.png`"
        :name="`チェックイメージ 
      `"
        :title="`ご依頼前に<br class='sp-only'>状態チェック`"
        :des="`状態はレコード査定に大きく左右します。リアリーグッドではジャケットの汚れや盤の傷など細かく6段階で評価します。カバーやケースに収納され丁寧に保管されているものは、満額査定の期待が持てます。埃が被った状態でも依頼前にジャケットを軽く拭いて掃除しておくと、査定時の印象が上がります。`"
      />
    </div>
    <div v-else-if="pageType === `openreel`">
       <UiBoxPoint
        :imgpath="`openreel-point01.png`"
        :name="`輸入盤イメージ 
      `"
        :title="`オープンリールデッキは電源を確認`"
        :des="`電源が入り通電していればOKです。動作不良でも、買取可能です。ダストカバーや付属品などが残っていると査定時に買取アップが期待できます。メーカーと型式をお問い合わせください。`"
      />
       <UiBoxPoint
        :imgpath="`openreel-point02.png`"
        :name="`ミュージックテープイメージ 
      `"
        :title="`ミュージックテープはジャンルを確認`"
        :des="`ジャズやロックのテープは人気が高く、特に日本製のテープは稀少度が更に増します。また2トラック38cmで録音された作品は高額買取出来ます。クラシックやその他のジャンルでも作品によって高価買取が期待できます。是非お問い合わせください。`"
      />
       <UiBoxPoint
        :imgpath="`record06.png`"
        :name="`オープンリールイメージ 
      `"
        :title="`録音用オープンリールテープ(生テープ)`"
        :des="`MAXELLやTEAC製は人気が高く、メタルリール (金属製のリール)は高価買取出来ます。 特に10号(30cm)の大きさが人気です。未使用品はもちろん、使用済み、箱がなくなったものでもコンディションが良ければ高価買取が期待できます。その他オープンリールテープはすべて買取可能です。お問い合わせお待ちしております。`"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxPoint from "@/components/Ui/BoxPoint";
export default {
  name: "classicrecord-assessment-points",
  components: {
    UiBoxPoint,
  },
  props: {
    pageType:String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title-cover{
  padding-top: 200px;
  @media screen and (max-width: 768px){
    padding-top: 100px;
  }
}

</style>