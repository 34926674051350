<template>
  <div class="home-customers-voice">
    <div class="title-cover space-150">
      <img :src="`${rootUrl}/img/title04.png`" alt="お客様の声" />
    </div>
    <p class="center space-50 top">
      ご利用いただいたお客様の声をご紹介します。高価買取実績が多数あります。
    </p>
    <div class="box-voice-cover space-100">
      <div class="box-voice">
        <UiBoxVoice
          :title="`ジャズ以外にも色々ありましたが、まとめて買い取ってくれました。`"
          :subTitle="`ジャズレコードを含む300枚を96,000円で買取させて頂きました`"
          :detail="`家にあったジャズのレコード100枚くらいと、その他邦楽や洋楽のレコードをまとめて買い取ってもらいました。<br>引っ越しが近く、急いでいると伝えたら翌日には引き取りに来てくれました。棚にしまったままだったのですが、全てお店の方がやってくれたので助かりました。`"
          :target="`愛知県 / 50代 / 女性`"
          class="box"
        />
        <UiBoxVoice
          :title="`細かく専門的な査定で、<br>安心してお願いできました。`"
          :subTitle="`ジャズのレコード230枚を158,000円で買取させて頂きました`"
          :detail="`大事に集めてきたレコードでオリジナル盤など貴重なものもあったので、手放すのを迷っていましたが、出張引き取りの際にその場でお見積りをしていただけました。どのレコードが何故価値があるかも丁寧に説明していただき、ジャズに精通していることが良くわかりました。レコードの価値の分かる専門店なので安心してお願いできました。`"
          :target="`岐阜県 / 70代 / 男性`"
          class="box"
        />
      </div>

      <div class="box-voice">
        <UiBoxVoice
          :title="`電話で丁寧に説明してくれたので<br class='pc-only'>良かったです。`"
          :subTitle="`ジャズのレコード80枚を50,000円で買取させて頂きました`"
          :detail="`父の遺品で、レコードのことは何もわからないのでお電話で相談しました。タイトルやレコードの番号などを伝えたら、おおよその値段を見積もってくれました。買取してもらうのが初めてで、私は少し不安だったのですが、丁寧に説明してくれたのでよかったです。`"
          :target="`滋賀県 / 30代 / 女性`"
          class="box"
        />

        <UiBoxVoice
          :title="`その場ですぐお見積もり<br>してくれました`"
          :subTitle="`ジャズのレコード20枚を7,800円で買取させて頂きました`"
          :detail="`ジャズの国内盤中心に20枚くらいを店舗に持ち込んで買取してもらいました。ジャケットも綺麗で帯などもついていたので思ったより値段を付けてもらえました。その場ですぐお見積もりしてくれて、その日のうちに支払いまでしてくださったので助かりました。`"
          :target="`愛知県 / 50代 / 男性`"
          class="box"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxVoice from "@/components/Ui/BoxVoice.vue";
export default {
  name: "home-if-you-buy",
  components: {
    UiBoxVoice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-customers-voice {
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
  .title-cover {
    img {
      margin-top: 100px;
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }
  .box-voice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .box {
      width: 49%;
      @media screen and (min-width: 581px) and (max-width: 1024px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}
</style>