<template>
  <div class="ui-box-service">
    <div class="box-cover"
    >
      <!-- <h3
        :class="{
          typeA: type === 'orange',
          typeB: type === 'green', 
          typeC: type === 'C', //blue
        }"
      >
        {{ title }}
      </h3> -->
      <img :src="`${rootUrl}/img/${imgpath}`" :alt="name" />
      <div class="box-right">
      <div class="title">
      <p v-html="title"></p>
      </div>
      <p v-html="des" class="des"></p>

    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-box-service",
  components: {},
  props: {
    imgpath: String,
    name: String,
    title: String,
    des: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-box-service {
  margin-top: 100px;
  @media screen and (max-width: 768px){
    margin-top: 30px;
  }
.box-cover{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px){
    flex-direction: column;
    align-items: center;
  }
  img{
width: 48%;
object-fit: contain;


      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
        margin-top: 30px;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
        margin-top: 30px;
      }

  }
  .box-right{
width: 48%;
padding-top: 10px;


      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
.title{
  font-size: 36px;
  font-weight: 700;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 20px;
  line-height: 1.4em;
  @media screen and (max-width: 768px){
    font-size: 26px;
  }
}
  }
}
}
</style>
