<template>
  <div class="result-register wrap960 space-100">
    <h1>買い取り実績 追加・編集</h1>
    <div class="cover">
      <div class="row" style="margin-bottom:0;">
        <p class="title">タイトル</p>
        <input v-model="resultData.title" type="text" class="input" /><br />
      </div>
      <div style="text-align: right">
        <p :class="color2(resultData.title.length)">
        残り{{ remaining2 }}文字
        </p>
        </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.title"
        style="text-align: right"
      />
      <div class="row">
        <p class="title">カテゴリー</p>
        <el-select
          v-model="resultData.categoryId"
          placeholder="選択してください"
        >
          <el-option
            v-for="(categoryData, index) in categoryList"
            :key="index"
            :label="categoryData.name"
            :value="categoryData.id"
          >
          </el-option>
        </el-select>
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.category"
        style="text-align: right"
      />
      <div class="row" style="margin-bottom: 0">
        <p class="title">自由入力欄</p>
        <textarea v-model="resultData.text" type="text" class="text input" />
      </div>
      <div style="text-align: right">
        <p :class="color(resultData.text.length)">残り{{ remaining }}文字</p>
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.text"
        style="text-align: right"
      />
      <div class="row">
        <p class="title">参考価格</p>
        <input v-model="resultData.price" type="number" class="input" />
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.price"
        style="text-align: right"
      />

      <!-- ↓↓ この書き方でアップロードした画像表示できるよ ↓↓ -->
      <img :src="`${rootUrl}/storage/${resultData.imgPath}`" />
      <!-- ↑↑ この書き方でアップロードした画像表示できるよ ↑↑ -->

      <div class="row" style="display: flex; align-items: center">
        <span style="margin-right: 10px">変更後: </span>
        <input type="file" @change="fileSelected" />
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.uploadFile"
        style="text-align: right"
      />

      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <el-button @click="registerresultData" type="primary">登録</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiplayValidationErrorMessages from "@/components/Ui/DisplayValidationErrorMessages.vue";

export default {
  name: "test-register-result",
  components: {
    DiplayValidationErrorMessages,
  },
  props: {
    // categoryId: String, // propsで来ている想定
    resultId: String, // propsで来ている想定（0なら新規追加。それ以外は編集。）
  },
  data() {
    return {
      resultData: {
        title: "",
        text: "",
        categoryId: "",
        price: 0,
        imgPath: "images/default.jpg", // アップロード機能で記入される想定
        sort: 0,
      },
      uploadFile: null,
      validationErrorData: {
        categoryId: [],
        resultId: [],
        title: [],
        text: [],
        price: [],
        imgPath: [],
        sort: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "categoryList"]),
    remaining: function () {
      return 36 - this.resultData.text.length;
    },
    remaining2: function () {
      return 26 - this.resultData.title.length;
    },
  },
  watch: {
    resultId: {
      handler() {
        if (this.resultId === "0") {
          //
        } else {
          this.resultData = JSON.parse(
            JSON.stringify(this.$store.getters["resultData"](this.resultId))
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
      color(resultDataTextLength) {
      if ( resultDataTextLength <= 36) {
        return "black";
      }
      if (resultDataTextLength > 36) {
        return "red";
      }
      return "black";
    },
    color2(resultDataTitleLength){
      if ( resultDataTitleLength <= 26) {
        return "black";
      }
      if (resultDataTitleLength > 26) {
        return "red";
      }
      return "black";
    },
    fileSelected(event) {
      this.uploadFile = event.target.files[0];
    },
    registerresultData() {
      if (this.resultData.text.length <= 36 && this.resultData.title.length <= 26) {
        const formData = new FormData();
        // formData.append("category_id", this.categoryId);
        formData.append("result_id", this.resultId);
        formData.append("title", this.resultData.title);
        formData.append("category_id", this.resultData.categoryId);
        formData.append("text", this.resultData.text);
        formData.append("price", this.resultData.price);
        formData.append("sort", this.resultData.sort);
        formData.append("upload_file", this.uploadFile);
        this.$axios
          .post(`${this.rootUrl}/api/result/register-result-data`, formData)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$router.go(-1);
              this.validationErrorData = {};
              return;
            } else if (response.data.status === 400) {
              this.validationErrorData = response.data.validationErrorData;
              return;
            } else {
              alert("エラーが発生しました。");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("タイトルは26文字以内、自由入力欄は36文字以内でおかきください。");
        return;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 50px;
  text-align: center;
  background-color: #f7f7f7;
  padding: 20px 0;
}
.cover {
  width: 100%;
  padding-bottom: 100px;
  margin: 0 auto;
  .row {
    display: flex;
    margin: 20px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    // width: 100%;
    .title {
      width: 200px;
    }
    .input {
      width: 80%;
      font-size: 16px;
      padding-left: 10px;
      @media screen and(max-width: 767px) {
        width: 95%;
        font-size: 17px;
      }
    }
    .text {
      min-height: 300px;
      padding: 10px;
    }
  }
}
.black {
color: black;
}
.red {
  color: red;
}
</style>