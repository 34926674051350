<template>
  <div class="ctrl-login">
    <div class="wrap">
      <CtrlResultRegisterResult :resultId="resultId" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlResultRegisterResult from "@/components/Ctrl/RegisterResult.vue";
export default {
  name: "ctrl-login",
  components: {
    CtrlResultRegisterResult,
  },
  props: {
    // categoryId:String,
    resultId: String,
  },
  data() {
    //とりあえずこれ使うー↓
    return {
      // title: "",
      // categoryId:"",
      // text:"",
      // price:"",
      // imgPath:"",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
