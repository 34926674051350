<template>
  <div class="ctrl-login wrap1100 space-100 center-c">
    <h1>管理画面</h1>
    <div class="panel">
      <el-input
        type="email"
        autocomplete="on"
        placeholder="登録メールアドレス"
        v-model="email"
      >
        <template slot="prepend">ログインID</template>
      </el-input>

      <el-input
        type="password"
        autocomplete="on"
        placeholder="123456789abcdefg"
        v-model="password"
      >
        <template slot="prepend">パスワード</template>
      </el-input>

      <el-button @click="login" type="success">ログイン</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ctrl-login",
  components: {},
  data() {
    return {
      email: "", //yusuke32123@gmail.com
      password: "" //gH4SctnU
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    login() {
      let params = new URLSearchParams();
      params.append("email", this.email);
      params.append("password", this.password);
      this.$axios
        .post(`${this.rootUrl}/api/auth/login`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.push("/ctrl")
            return;
          } else {
            alert("ログインできませんでした。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: 500px;
  width: 100%;
  background-color: #f5f5f5;
  padding: 100px;
  margin-bottom: 50px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and(max-width: 767px){
    padding: 100px 0;
  }
}
.el-button {
  width: 200px;
}
.el-input{
  width: 90%;
  padding-bottom: 50px;
  @media screen and(max-width: 767px){
    width: 95%;
    font-size: 17px;
  }
}
h1 {
  text-align: center;
  // color: var(--black);
  // border-bottom: 1px solid black;
  padding-bottom: 15px;
  font-size: 22px;
}
</style>
