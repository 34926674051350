<template>
  <div class="ui-box">
    <div class="box-cover">
      <h3
        :class="{
          typeA: type === 'orange',
          typeB: type === 'green',
          typeC: type === 'C', //blue
        }"
      >
        {{ title }}
      </h3>
      <img :src="`${rootUrl}/img/${imgpath}`" :alt="name" />
      <p v-html="text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-box",
  components: {},
  props: {
    title: String,
    imgpath: String,
    name: String,
    text: String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-box {
  padding-bottom: 100px;
  h3 {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.08em;
    @media screen and (max-width: 768px) {
      line-height: 1.2em;
    }
  }
  .typeA {
    color: var(--orange);
  }
  .typeB {
    color: var(--green);
  }
  img {
    margin-top: 10px;
  }
  p {
    padding: 20px 0;
    line-height: 1.4em;
    /deep/.big {
      font-weight: bold;
    }
    /deep/.kome {
      font-size: 16px;
    }
  }
    @media (min-width: 751px) {
    /deep/a[href*="tel:"] {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }
}
</style>
