<template>
  <div class="home-how-to-buy-in-store">
    <div class="flow">
      <p class="title center">店頭買取の流れ</p>
      <div class="flow-box">
        <div class="box center-c">
          <img
            :src="`${rootUrl}/img/in-store01.png`"
            alt="かごイラスト"
            class="truck"
          />
          <p>買い物のついでに<br />1点からお持ち込みOK</p>
        </div>
        <div class="box center-c">
          <img
            :src="`${rootUrl}/img/in-store02.png`"
            alt="お支払いイラスト"
            class="box"
          />
          <p>当日その場で<br />お支払い</p>
        </div>
        <div class="box center-c">
          <img :src="`${rootUrl}/img/in-store03.png`" alt="電車イラスト" />
          <p style="font-size: 18px">
            上前津駅より徒歩5分<br />大津通り沿いでアクセス良好
          </p>
        </div>
      </div>
    </div>

    <UiBox
      :type="`green`"
      :title="`お持ち込み`"
      :imgpath="`in-store04.jpg`"
      :name="``"
      :text="`査定は1点からOK！数点であればその場で査定いたします。<br>身分証(※運転免許証、健康保険証、パスポート、学生証、住民票の写しなど)をお持ちの上ご来店ください。<br>大津通り沿いで、お車でのアクセスも良好です。<br>お車での大量のお持ち込みの場合は、荷下ろしさせていただきます。`"
    />

    <UiBox
      :type="`green`"
      :title="`その場で査定`"
      :imgpath="`delivery08.jpg`"
      :name="`査定結果イメージ`"
      :text="`専門スタッフが丁寧かつスピーディーに査定させていただきます。
内容・量によってはお預かりする場合がございます。`"
    />

    <UiBox
      :type="`green`"
      :title="`即日お支払い`"
      :imgpath="`delivery09.jpg`"
      :name="`お支払いイメージ`"
      :text="`査定額のご了承を頂けましたら、その場でお支払いいたします。`"
    />

    <div class="access">
      <p class="title">アクセス ACCESS</p>

      <div class="access-cover">
        <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.1372050752743!2d136.90260341558633!3d35.153198780320864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003776ed2e36ef9%3A0x68237c03ae9b7e9a!2z44Oq44Ki44Oq44O844Kw44OD44OJ!5e0!3m2!1sja!2sjp!4v1652162457277!5m2!1sja!2sjp" width="100%" height="335px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="access-detail">
          <p>
            <span>【Address】</span><br />愛知県名古屋市中区橘1-27-6 石橋ビル2F
          </p>
          <p><span>【Tel】 </span><br />052-211-7931</p>
          <p><span>【営業時間】 </span><br />12:00-19:00 年中無休</p>
        </div>
      </div>

      <div class="title-cover">
        <img
          :src="`${rootUrl}/img/access01.png`"
          alt="徒歩、電車でお越しの方"
        />
      </div>
      <div class="map-detail">
        <p>
          <span>【上前津駅から】</span><br />
          上前津駅6番出口より南へ徒歩6分。下前津の交差点を越え、美容KOKOの横の白い建物の2階です。
        </p>
        <div class="map">
          <img :src="`${rootUrl}/img/access-map01.jpg`" alt="地図1" />
          <img :src="`${rootUrl}/img/access-map02.jpg`" alt="地図2" />
          <img :src="`${rootUrl}/img/access-map03.jpg`" alt="地図3" />
          <img :src="`${rootUrl}/img/access-map04.jpg`" alt="地図4" />
        </div>
      </div>
      <div class="map-detail">
        <p>
          <span>【東別院駅から】</span><br />
          東別院駅4番出口より北方向へ徒歩5分。4番出口西側の交差点をメーテレ側に渡り、スターバックスコーヒーを越えて北に直進。下茶屋公園交差点を越えて100mほど先の左手側にございます。
        </p>
        <div class="map">
          <img :src="`${rootUrl}/img/access-map05.jpg`" alt="地図5" />
          <img :src="`${rootUrl}/img/access-map06.jpg`" alt="地図6" />
          <img :src="`${rootUrl}/img/access-map07.jpg`" alt="地図7" />
          <img :src="`${rootUrl}/img/access-map04.jpg`" alt="地図8" />
        </div>
      </div>

      <div class="title-cover">
        <img :src="`${rootUrl}/img/access02.png`" alt="お車でお越しの方" />
        <div class="map-detail">
          <p>
            大津通り沿い、東別院交差点を北へ直進。下茶屋公園交差点を越えて100mほど先の左手側にございます。店舗北側100m先左側コインパーキングあり。店頭買取お持ち込みの方には駐車券サービスしております。
          </p>
          <div class="map">
            <img :src="`${rootUrl}/img/access-map09.jpg`" alt="地図9" />
            <img :src="`${rootUrl}/img/access-map10.jpg`" alt="地図10" />
            <img :src="`${rootUrl}/img/access-map11.jpg`" alt="地図11" />
            <img :src="`${rootUrl}/img/access-map12.jpg`" alt="地図12" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox from "@/components/Ui/Box.vue";
export default {
  name: "home-how-to-buy-in-store",
  components: {
    UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-how-to-buy-in-store {
  padding: 0 100px;
  padding-bottom: 80px;
  line-height: 1.4em;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
      @media screen and (min-width: 581px) and (max-width: 768px) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 580px) {
      width: 100%;
    }
  .flow {
    .title {
      color: #29aae1;
      font-size: 26px;
      font-weight: 700;
      padding: 50px 0;
    }
  }

  .flow-box {
    display: flex;
    justify-content: space-between;
    height: 200px;
    margin: 50px 0 100px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
    .box {
      width: 32%;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 40px;
      }
      img {
        width: 53%;
        @media screen and (max-width: 768px) {
          width: 40%;
          margin-bottom: 20px;
        }
        // margin-bottom: 10px;
      }
      .truck {
        width: 50%;
      }
      .box {
        width: 50%;
      }
      p {
        // line-height: 1.3em;
        font-weight: bold;
        text-align: center;
        font-size: 18px;
      }
    }
  }
  .pdf {
    padding-bottom: 100px;
    img {
      width: 60%;
    }
    a:hover {
      opacity: 0.7;
    }
  }
  .access {
    .title {
      color: var(--green);
      border-bottom: 1px solid black;
      padding-bottom: 30px;
      font-size: 28px;
      font-weight: 700;
    }
    .access-cover {
      display: flex;
      justify-content: space-between;
      padding: 50px 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .map {
        width: 55%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      .access-detail {
        width: 40%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .map-detail,
    .access-detail {
      font-size: 18px;
      p {
        margin: 20px 0;
      }
      span {
        margin-left: -10px;
      }
    }
    .map-detail {
      padding-top: 50px;
      @media screen and (max-width: 768px){
        padding-top: 20px;
      }
      span {
        font-weight: 700;
      }
      .map {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
                @media screen and (max-width: 768px){
        flex-direction: column;
        }
        img {
          width: 49%;
          margin-bottom: 2%;
                  @media screen and (max-width: 768px){
        width: 100%;
        }
        }
      }
    }
  }
  .title-cover {
    padding-top: 100px;
    @media screen and (max-width: 768px) {
      padding-top: 50px;
    }
    img {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
}
</style>