<template>

      <div
        class="home-gallery">
    <UiSliderBox
      :images="images"
      :swiperOption="swiperOption"
      :height="50"
      :width="100"
      :button="false"
      class="slider"
    />
        
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import UiSliderBox from "../Ui/SliderBox.vue";

export default {
  name: "home-gallery",
  components: {
UiSliderBox,
  },
  props: {},
  data() {
    return {  images: [
        { id: 1, imgpath: "slider01.jpg", name: "レコード1" },
        { id: 2, imgpath: "slider02.jpg", name: "レコード2" },
        { id: 3, imgpath: "slider03.jpg", name: "レコード3" },
        { id: 4, imgpath: "slider04.jpg", name: "レコード4" },
        { id: 5, imgpath: "slider05.jpg", name: "レコード5" },
        { id: 6, imgpath: "slider06.jpg", name: "レコード6" },
        { id: 7, imgpath: "slider07.jpg", name: "レコード7" },
        { id: 8, imgpath: "slider08.jpg", name: "レコード8" },
        { id: 9, imgpath: "slider09.jpg", name: "レコード9" },
        { id: 10, imgpath: "slider10.jpg", name: "レコード10" },
        { id: 11, imgpath: "slider11.jpg", name: "レコード11" },
        { id: 12, imgpath: "slider12.jpg", name: "レコード12" },
      
      ],
      swiperOption: {
        slidesPerView: 9,
        spaceBetween: 10,
        speed: 1000,
        effect: "slide",
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // 1200: {
        //   slidesPerView: 6,
        // },
        // 600: {
        //   slidesPerView: 6,
        //   spaceBetween: 0,
        // },
      },};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    if (window.matchMedia("(min-width:768px)").matches) {
      //no action.
    } else if (window.matchMedia("(max-width: 767px)").matches) {
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "3";
      this.swiperOption.speed = 800;
      this.swiperOption.spaceBetween = 30;
      // this.swiperOption.autoplay.delay = "400";
    } else {
      //no action.
    }
  },
  methods: {},

};
</script>

<style lang="scss" scoped>
/deep/.slider {
  box-sizing: content-box!important;
  width: 100%;
  margin: 0 auto;
  margin-top: 36px;
   @media screen and (max-width: 767px){
     width: 97%;
     margin-top: 15px;

   }
}
// /deep/.VueCarousel-navigation-button{
//   img{
//     width: 30px;
//     @media screen and (max-width: 767px){
//       width: 15px;
//   }
//  }
// }
//   .VueCarousel-slide{
//     display: flex;
//   align-items: center;
//   justify-content: center;
//   height:700px;
//   img{
//     object-fit: cover;
//   }
// }

// /deep/.VueCarousel-dot-container {
//   width: 150px;
//   display: flex !important;
//   justify-content: space-between;
//   padding-top: 20px !important;
//   @media screen and (max-width: 768px) {
//     width: 100px;
//   }
// }

</style>
