<template>
  <div class="home-how-to-buy-mobile-service">
    <div class="flow">
      <p class="title center">出張買取の流れ</p>
      <div class="flow-box">
        <div class="box center-c">
          <img
            :src="`${rootUrl}/img/mobile-service.png`"
            alt="出張買取トラック"
            class="truck"
          />
          <p style="line-height: 1.3em">
            愛知・岐阜・三重<br />東海エリアはお任せ下さい<br /><span
              style="font-size: 15px"
              >（その他エリアもご相談下さい。）</span
            >
          </p>
        </div>
        <div class="box center-c">
          <img
            :src="`${rootUrl}/img/mobile-service01.png`"
            alt="カレンダーイラスト"
            class="box"
          />
          <p>即日出張<br />ご相談下さい</p>
        </div>
        <div class="box center-c">
          <img
            :src="`${rootUrl}/img/mobile-service02.png`"
            alt="引っ越しイラスト"
          />
          <p>引越しや遺品整理にも<br />素早く対応します。</p>
        </div>
      </div>
    </div>

    <UiBox
      :type="`green`"
      :title="`お電話またはメールでお問い合わせください`"
      :imgpath="`delivery02.jpg`"
      :name="``"
      :text="`東海エリア、大量整理、自分では動かせないオーディオもお任せください！<br>
買取専用フリーダイヤル、もしくはメールフォームよりお申込ください。<br><br><span class='big'>・0120-590-510(受付11:00～19:00)</span><br><br>取りに来て欲しいお品物、日時をお伝えください。<br>スケジュールが合えば当日出張も可能です。引越しや遺品整理など早急なご依頼にも対応いたします。<br>※ご依頼品の内容によっては東海エリア以外も出張可能ですのでお気軽にお問い合わせください。
`"
    />

    <UiBox
      :type="`green`"
      :title="`お引き取り`"
      :imgpath="`mobile-service03.jpg`"
      :name="`引き取りイメージ`"
      :text="`お約束の日にご自宅までお伺いいたします。身分証(※運転免許証、健康保険証、パスポート、学生証、住民票の写しなど)をご用意の上お待ちください。<br><br>ご依頼品は棚や押し入れ、箱にしまったままの状態でもOKです。<br>当店スタッフがすべて運び出します。`"
    />

    <UiBox
      :type="`green`"
      :title="`査定結果をご連絡`"
      :imgpath="`delivery08.jpg`"
      :name="`査定結果イメージ`"
      :text="`お預かりしたご依頼品を、専門スタッフが丁寧かつスピーディーに査定させていただきます。<br>結果はお電話、メールにてご連絡させていただきます。<br>`"
    />

    <UiBox
      :type="`green`"
      :title="`ご了承後、お振込でお支払い`"
      :imgpath="`delivery09.jpg`"
      :name="`お振込イメージ`"
      :text="`定額のご了承を頂けましたら、当日、または翌日中にご指定の口座へお振込いたします。`"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox from "@/components/Ui/Box.vue";
export default {
  name: "home-how-to-buy-mobile-service",
  components: {
    UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-how-to-buy-mobile-service {
  padding: 0 100px;
  line-height: 1.4em;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
  @media screen and (min-width: 581px) and (max-width: 768px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
  }
  .flow {
    .title {
      color: var(--green);
      font-size: 26px;
      font-weight: 700;
      padding: 50px 0;
    }
  }

  .flow-box {
    display: flex;
    justify-content: space-between;
    height: 200px;
    margin: 50px 0 100px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
    .box {
      width: 32%;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 40px;
      }
      img {
        width: 45%;
        @media screen and (max-width: 768px) {
          width: 50%;
          margin-bottom: 20px;
        }
      }
      .truck {
        width: 80%;
      }
      .box {
        width: 50%;
      }
      p {
        // line-height: 1.3em;
        font-weight: bold;
        text-align: center;
        font-size: 18px;
      }
    }
  }
  .pdf {
    padding-bottom: 100px;
    img {
      width: 60%;
    }
    a:hover {
      opacity: 0.7;
    }
  }
}
</style>