<template>
  <div class="test-register-product wrap960 space-100">
    <h1>{{ categoryData.name }}<br />記事内 掲載商品 追加・編集</h1>
      <el-button-group class="tab">
        <el-button @click="toggleTab(1)" v-bind:class="{'active': tabNumber === 1}">フォルダ表示</el-button>
        <el-button @click="toggleTab(2)" v-bind:class="{'active': tabNumber === 2}">掲載順表示</el-button>
      </el-button-group>
    <div class="folder record-box" v-show="tabNumber === 1">
      <div class="item-box-cover">
        <div class="item-box" v-for="(groupData, index) in productGroupList" :key="index">
          <h3 v-show="groupData.id !== 0">
            {{ groupData.name }}
            <el-button @click="editDialog = true; editId = groupData.id; newDirName = groupData.name" type="" class="rename">名称変更</el-button>
            <el-button @click="deleteDialog = true; deleteDirName = groupData.name; deleteId = groupData.id" type="danger" class="rename delete">削除</el-button>
          </h3>
          <div class="group-box" v-bind:class="{'group': groupData.id !== 0}">
            <div
            class="item-box"
            v-for="(productData, productIndex) in productList"
            :key="productIndex"
            >
              <div v-if="productData.productGroupId === groupData.id" class="product-list">
                <div class="product">
                  <p>{{ productData.title }}</p><span class="article">{{ productData.article }}</span>
                </div>
                <div class="button-cover2">
                  <router-link
                    :to="`/ctrl/product/${categoryId}/product-detail/${productData.id}`"
                  >
                    <el-button
                      type="primary"
                      style="
                        vertical-align: top;
                        height: 100%;
                        margin-right: 10px;
                      "
                      >確認・修正</el-button
                    ></router-link
                  >
                  <el-button @click="deleteProduct(productData.id)"
                    >削除</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
      </div>
    </div>
    <div class="record-box" v-show="tabNumber === 2">
      <div class="item-box-cover">
        <draggable
          v-model="productList"
          element="div"
          :options="{ animation: 300 }"
          class="doraggu"
        >
          <div
            class="item-box"
            v-for="(productData, productIndex) in productList"
            :key="productIndex"
          >
            <div class="product-list cursor-grab">
              <div class="product">
                <p>{{ productData.title }}</p><span class="article">{{ productData.article }}</span>
              </div>
              <div class="button-cover2">
                <router-link
                  :to="`/ctrl/product/${categoryId}/product-detail/${productData.id}`"
                >
                  <el-button
                    type="primary"
                    style="
                      vertical-align: top;
                      height: 100%;
                      margin-right: 10px;
                    "
                    >確認・修正</el-button
                  ></router-link
                >
                <el-button @click="deleteProduct(productData.id)"
                  >削除</el-button
                >
                <el-checkbox
                  v-model="productData.visible"
                  style="margin-left: 10px"
                  >Topに反映する</el-checkbox
                >
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <router-link :to="`/ctrl/product/${categoryId}/product-detail/0`">
          <el-button type="danger" class="new-button"
            >新規作成</el-button
          ></router-link
        >
        <el-button @click="registerVisbleConfig()">確定</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="editDialog" title="フォルダ名を変更します">
      <el-input v-model="newDirName" :value="newDirName" type="text"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editDialog = false">キャンセル</el-button>
          <el-button type="primary" @click="resisterProductGroup()">
            変更
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="deleteDialog" title="確認">
    <span>「{{ deleteDirName }}」を削除します</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteDialog = false">キャンセル</el-button>
        <el-button type="danger" @click="deleteProductGroup()">
          削除
        </el-button>
      </span>
    </template>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ctrl-product-products",
  components: {
    draggable,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {
      tabNumber: 2,
      productList: [],
      productGroupList: [],
      productGList: [],
      editDialog: false,
      deleteDialog: false,
      newDirName: "",
      deleteDirName: "",
      editId: 0,
      deleteId: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
  watch: {
    editDialog: {
      handler() {
        console.log(this.editDialog);
        console.log(this.editId);
        // this.newDirName = this.editId;
      }
    }
  },
  created() {
    this.$store.dispatch('setCategoryList');
    this.getProductList();
    this.getProductGroupList();
  },
  methods: {
    toggleTab(num) {
      this.tabNumber = num;
    },
    getProductList() {
      this.productList = this.categoryData.productList;
      this.productList.forEach(product => {
        product.article = "";
        product.articles.forEach(article => {
          product.article += " [" + article.title + "]";
        })
      });
    },
    resisterProductGroup() {
      if (this.newDirName === "") {
        alert("フォルダ名を入力してください");
        return;
      }
      const formData = new FormData();
      formData.append("id", this.editId);
      formData.append("name", this.newDirName);
      this.$axios
      .post(`${this.rootUrl}/api/product/register-product-group`, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          
          this.getProductGroupList();
          this.editDialog = false;
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });        
    },
    deleteProductGroup() {
      
      const formData = new FormData();
      formData.append("id", this.deleteId);
      this.$axios
      .post(`${this.rootUrl}/api/product/delete-product-group`, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          
          this.$store.dispatch('setCategoryList');
          this.getProductGroupList();
          this.deleteDialog = false;
          location.reload();
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });        
    },
    getProductGroupList() {
      this.$axios
      .get(`${this.rootUrl}/api/product/get-product-group-list?board=1`)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          
          this.productGroupList = response.data.list;

          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });        
    },
    deleteProduct(productId) {
      if (confirm("本当に削除してもいいですか？")) {
        let params = new URLSearchParams();
        params.append("product_id", productId);
        this.$axios
          .post(`${this.rootUrl}/api/product/delete-product`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("setCategoryList").then(response => {
                console.log(response);
                this.getProductList();
              });
              return;
            } else {
              alert("エラーが発生しました。");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    registerVisbleConfig() {
      let visibleList = [];
      let sort = 0;
      this.productList.forEach((productData) => {
        visibleList.push({
          productId: productData.id,
          visible: productData.visible === true ? 1 : 0,
          sort: sort,
        });
        sort++;
      });
      console.log(visibleList);

      if (
        visibleList.filter((visibleData) => visibleData.visible === 1).length >=
        5
      ) {
        //
      } else {
        alert("表示は5個以上にしてください。");
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("visible_list", JSON.stringify(visibleList));
      this.$axios
        .post(`${this.rootUrl}/api/product/register-visible-config`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert("変更しました。");
            this.$store.dispatch("setCategoryList");
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  list-style-type: none;

  button{
    width: 200px;
  }

  .active {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }
}
.folder{
  cursor: initial;
  .item-box {
    width: 100%;
  }
}
.record-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  .sub-title {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
    img {
      width: 50px;
      vertical-align: middle;
      margin-right: 10px;
    }
    span {
      font-weight: 700;
      vertical-align: middle;
      font-size: 36px;
    }
  }
  h3 {
    font-size: 20px;
  }
  .rename {
    margin: 0 0 0 10px;
    padding: 8px 13px;
    font-size: 12px;
    line-height: 0.8;
    vertical-align: text-bottom;
  }
  .group-box {
    padding: 20px;
    margin-bottom: 20px;
  }
  .group{
    border: 1px solid #cdcdcd;
  }
  .item-box-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  .product-list {
    background-color: #f7f7f7;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .button-cover2 {
      display: flex;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
        margin-top: 20px;
      }
      align-items: center;
    }
    p {
      width: 100%;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
    .product {
      width: 100%;
    }
    .article {
      font-size: 0.8rem;
    }
  }
  .cursor-grab {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .explanation {
    // background-color: var(--yellow2);
    // border-bottom: 2px var(--yellow2) solid;
    width: 40%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .bnutton-cover {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .doraggu {
    width: 100%;
  }
}
</style>