<template>
  <div class="home-qa">
    <div class="title-cover">
        <img :src="`${rootUrl}/img/title05.png`" alt="買取についてよくある質問" />
  </div>

    <div class="qa-list">
      <div class="qa-box" v-for="item in items" :key="item.id">
        <p class="question">Q:{{item.question}}</p>
        <p class="answer">A:{{item.answer}}</p>
      </div>


    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "home-if-qa",
  components: {},
  props: {},
  data() {
    return {
      items:[
        {id:1,question:"どんな物を買取ってもらえますか？",answer:"リアリーグッドでは、レコードをはじめ、CD、オープンリール、DVD＆ブルーレイ、書籍、カセットテープ、オーディオ全般、幅広いアイテムを買取しております。ご不明な点がありましたらお気軽にお問合せください。"},
        {id:2,question:"費用は掛かりますか？",answer:"リアリーグッドでは、査定、出張、送料すべて無料です。お客様の費用のご負担 はございません。"},
        {id:3,question:"買取の方法にはどんなものがありますか？",answer:"「宅配買取」「出張買取」「店頭買取」の3つの方法がございます。 お客様のご都合に合わせて方法がお選びいただけます。"},
        {id:4,question:"店頭買取の場合、何か必要なものはありますか？",answer:"身分証明書が必要となります（運転免許証、保険証、パスポート、学生証等) お品物と一緒にお持ちください。"},
        {id:5,question:"身分証明書はなぜ必要なのですか？",answer:"お売りくださった方の身元確認は、古物営業法で定められておりますので記録させていただいております。それ以外の目的で使用することはございません。"},
        {id:6,question:"駐車場はありますか？",answer:"近隣のコインパーキングの駐車券をサービスしております。事前にお問い合わせください。買取品が多数になる場合、スタッフが荷下ろしに伺います 。お声掛けください。"},
        {id:7,question:"宅配買取を利用したいのですが、宅配業者の指定はありますか？",answer:"宅配便の指定業者はございません。お客様のご都合に合わせて最寄の宅配業者からお送りください。"},
        {id:8,question:"宅配買取は送料無料ですか？",answer:"送料無料となっております。ご依頼点数に応じてお客様ご負担頂く場合がございます。 お手数ですが事前にご相談ください。"},
        {id:9,question:"事前におよその買取金額を知りたい。",answer:"アーティスト名、タイトル、型番、状態をお伺いできれば、おおよその買取金額をお伝えすることができます。お電話またはメールにてお問い合わせください。"},
        {id:10,question:"値段がつかないものはどうなるの？",answer:"基本的に買取ができない商品はお返ししますが、処分ご希望の場合は当社で処分いたします。処分費用など一切発生しません。"},
        {id:11,question:"時間がないので店頭で買取商品を預けて、後日買取金額を受け取りに行っても良いですか？",answer:"問題ございません。お急ぎでない場合、買取商品を置いていかれる方も多くいらっしゃいます。"},

      ]
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-qa {
  .qa-list{
    width: 100%;
    font-weight: 700;
    margin: 100px 0;
    .qa-box{
      margin-bottom: 50px;

    .question{
      color: var(--orange);
      border-bottom: 1px solid black;
      margin-bottom: 30px;
      font-size: 24px;
      }
      .answer{

      }
    }
  }
}
</style>