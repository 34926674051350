<template>
  <div class="product-register wrap960 space-100">
    <h1>商品追加・編集</h1>
    <div class="cover" style="margin-bottom:0;">
      <div class="row">
        <p class="title">タイトル</p>
        <input v-model="productData.title" type="text" class="input" /><br />
      </div>
      <div style="text-align: right">
        <p :class="color2(productData.title.length)">
          残り{{ remaining2 }}文字
        </p>
      </div>
      <DiplayValidationErrorMessages
      :messageList="validationErrorData.title"
      style="text-align: right"
      />
      <div class="row" style="margin-bottom:0;">
        <p class="title">フォルダ</p>
        <el-select class="dir" v-model="productData.productGroupId" placeholder="選択してください">
        <el-option
          v-for="(productGroupData) in productGroupList"
          :key="productGroupData.id"
          :label="productGroupData.name"
          :value="productGroupData.id"
        >
        </el-option>
      </el-select>
      <el-button class="new-dir" type="success" @click="createDialog = true">新規作成</el-button>
      <el-dialog :visible.sync="createDialog" title="フォルダを新規作成します">
        <el-input v-model="newDirName" type="text"></el-input>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="createDialog = false">キャンセル</el-button>
            <el-button type="primary" @click="resisterProductGroup()">
              作成
            </el-button>
          </span>
        </template>
      </el-dialog>
      </div>
      <div class="row" style="margin-bottom:0;">
        <p class="title">自由入力欄</p>
        <textarea v-model="productData.text" type="text" class="text input" />
      </div>
      <div style="text-align: right">
        <p :class="color(productData.text.length)">残り{{ remaining }}文字</p>
      </div>
      <DiplayValidationErrorMessages
      :messageList="validationErrorData.text"
      style="text-align: right"
      />
      <div class="row">
        <p class="title">参考価格</p>
        <input v-model="productData.price" type="number" class="input" />
      </div>
      <DiplayValidationErrorMessages
      :messageList="validationErrorData.price"
      style="text-align: right"
      />
      
      <!-- ↓↓ この書き方でアップロードした画像表示できるよ ↓↓ -->
      <img :src="`${rootUrl}/storage/${productData.imgPath}`" />
      <!-- ↑↑ この書き方でアップロードした画像表示できるよ ↑↑ -->
      
      <div class="row" style="display: flex; align-items: center">
        <span style="margin-right: 10px">変更後: </span>
        <input type="file" @change="fileSelected" />
      </div>
      <DiplayValidationErrorMessages
      :messageList="validationErrorData.uploadFile"
      style="text-align: right"
      />
      
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <el-button @click="registerProductData" type="primary">登録</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiplayValidationErrorMessages from "@/components/Ui/DisplayValidationErrorMessages.vue";

export default {
  name: "test-register-product",
  components: {
    DiplayValidationErrorMessages,
  },
  props: {
    categoryId: String, // propsで来ている想定
    productId: String, // propsで来ている想定（0なら新規追加。それ以外は編集。）
  },
  mounted() {
    this.getProductGroupList();
  },
  data() {
    return {
      productData: {
        title: "",
        groupId: 0,
        text: "",
        price: 0,
        imgPath: "images/default.jpg", // アップロード機能で記入される想定
        sort: 0,
      },
      uploadFile: null,
      validationErrorData: {
        categoryId: [],
        productId: [],
        title: [],
        text: [],
        price: [],
        imgPath: [],
        sort: [],
      },
      productGroupList: [
        {
          id: 0,
          name: '未選択',
        }
      ],
      createDialog: false,
      newDirName: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    remaining: function () {
      return 80 - this.productData.text.length;
    },
    remaining2: function () {
      return 28 - this.productData.title.length;
    },
  },
  watch: {
    productId: {
      handler() {
        if (this.memberId === "0") {
          //
        } else {
          this.productData = JSON.parse(
          JSON.stringify(
          this.$store.getters["productData"](
          this.categoryId,
          this.productId
          )
          )
          );
        }
      },
      immediate: true,
    },
    createDialog: {
      handler() {
        if (this.createDialog === false) {
          this.newDirName = "";
        }
        console.log(this.productData.productGroupId);
      }
    }
  },
  methods: {
    color(productDataTextLength) {
      if ( productDataTextLength <= 80) {
        return "black";
      }
      if (productDataTextLength > 80) {
        return "red";
      }
      return "black";
    },
    color2(productDataTitleLength){
      if ( productDataTitleLength <= 28) {
        return "black";
      }
      if (productDataTitleLength > 28) {
        return "red";
      }
      return "black";
    },
    fileSelected(event) {
      this.uploadFile = event.target.files[0];
    },
    registerProductData() {
      if (this.productData.text.length <= 80 && this.productData.title.length <= 28) {
        const formData = new FormData();
        formData.append("category_id", this.categoryId);
        formData.append("product_id", this.productId);
        formData.append("product_data", JSON.stringify(this.productData));
        formData.append("upload_file", this.uploadFile);
        this.$axios
        .post(`${this.rootUrl}/api/product/register-product-data`, formData)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.go(-1);
            this.validationErrorData = {};
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      } else {
        alert("タイトルは18文字以内、自由入力欄は60文字以内でおかきください。");
        return;
      }
      
    },
    back() {
      this.$router.go(-1);
    },
    resisterProductGroup() {
      console.log(this.newDirName);
      if (this.newDirName === "") {
        alert("フォルダ名を入力してください");
        return;
      }
      const formData = new FormData();
      formData.append("id", 0);
      formData.append("name", this.newDirName);
      this.$axios
      .post(`${this.rootUrl}/api/product/register-product-group`, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          
          this.getProductGroupList();
          this.productData.productGroupId = response.data.id;
          this.createDialog = false;

          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });        
    },
    getProductGroupList() {
      this.$axios
      .get(`${this.rootUrl}/api/product/get-product-group-list`)
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          
          this.productGroupList = response.data.list;

          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });        
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 50px;
  text-align: center;
  background-color: #f7f7f7;
  padding: 20px 0;
}
.cover {
  width: 100%;
  padding-bottom: 100px;
  margin: 0 auto;
  .row {
    display: flex;
    margin: 20px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    
    // width: 100%;
    .title {
      width: 200px;
    }
    .new-dir {
      margin-left: 10px;
    }
    .input {
      width: 90%;
      font-size: 16px;
      padding-left: 10px;
      @media screen and(max-width: 767px) {
        width: 60%;
        font-size: 17px;
      }
    }
    .text {
      min-height: 300px;
      padding: 10px;
    }
    .dir {
      margin-left: -20px;
    }
  }
}
.black {
  color: black;
}
.red {
  color: red;
}
</style>