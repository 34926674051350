<template>
  <div class="ordercontract wrap960">
    <h1>特定商取引に関する<br class="sp-only">法律に基づく表示</h1>
    <table>
      <tr>
        <th>販売業者</th>
        <td>Really good (リアリーグッド)</td>
      </tr>
      <tr>
        <th>運営統括責任者</th>
        <td>片岡 兼人</td>
      </tr>
      <tr>
        <th>所在地</th>
        <td>愛知県名古屋市中区橘1-27-6 石橋ビル2F</td>
      </tr>
      <tr>
        <th>電話番号</th>
        <td>052-211-7931</td>
      </tr>
      <tr>
        <th>メールアドレス</th>
        <td>info@reallygood.jp</td>
      </tr>
      <tr>
        <th>商品代金以外の必要料金</th>
        <td>
          ・銀行振込でご購入の際の振込手数料。
          <br />・代金引換でご購入の際の代引手数料。
        </td>
      </tr>
      <tr>
        <th>申し込みの有効期限</th>
        <td>
          原則、受注確認（受注確認の為の自動送信メール発信）後、5日間とします。
        </td>
      </tr>
      <tr>
        <th>不良品</th>
        <td>
          商品到着日から7日間以内に生じました初期不良に付きましては交換もしくは修理にて対応をいたします。
        </td>
      </tr>
      <tr>
        <th>販売数量</th>
        <td>特に指定はございません（商品によって設定あり）</td>
      </tr>
      <tr>
        <th>引き渡し時期</th>
        <td>
          ご注文受付後、7日以内にお届けいたします。<br />
          天候不順・配送業者の都合・その他何らかの理由により
          遅延する場合はメール等でお知らせいたします。
        </td>
      </tr>
      <tr>
        <th>お支払い方法</th>
        <td>クレジット決済・ 銀行振込・代金引換</td>
      </tr>
      <tr>
        <th>お支払い期限</th>
        <td>
          <p>
            <span>・銀行振込の場合</span><br>
            ご注文日より5日以内に当店指定の口座へお振込みください。
            5日を超えた場合は一旦キャンセル扱いとさせていただきます。
            再度、御注文いただきますようお願いします。
          </p>

          <p>
             <span>・代金引換の場合</span><br>
            商品のお届け時に、佐川急便または日本郵便配達員に代金のお支払いをお願いします。
          </p>
        </td>
      </tr>
      <tr>
        <th>返品期限</th>
        <td>商品の返品・交換には初期不良の場合以外では応じられません。</td>
      </tr>
      <tr>
        <th>返品送料</th>
        <td>初期不良、発送商品間違いの場合、当店着払いにて対応いたします。</td>
      </tr>
    </table>
                <div class="button-cover">
        <el-button @click="back" type="info" class="back">前のページに戻る</el-button>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Ordercontract",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {       
     back() {
      this.$router.go(-1);
    },},
};
</script>
<style lang="scss" scoped>
.ordercontract {
  margin-bottom: 50px;
  h1 {
    margin-top: 100px;
    @media screen and (max-width: 768px) {
      margin-top: 50px;
    }
  }
  table {
    margin: 50px 0;
    tr {
      font-size: 16px;
      text-align: left;

        th,td{
        @media screen and (max-width: 768px){
          display: block;
        }
        }
      th {
        width: 200px;
        font-weight: bold;
      }
      td {
        padding: 10px;
        span{
          font-weight: bold;
        }
      }
    }
  }
    .button-cover{
    margin-top: 50px;
  }
}
</style>
