<template>
  <div class="ctrl-group-member-category">
    <!-- aaa<CtrlCtrlHome /> -->
  <router-view :categoryId="categoryId" :productId="productId"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import CtrlCtrlHome from "@/components/Ctrl/Home.vue";
export default {
  name: "CtrlGroupMemberCategory",
  components: {
    // CtrlCtrlHome,
  },
  props: { 
    categoryId: String,
    productId: String,
     },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>