import { render, staticRenderFns } from "./Results.vue?vue&type=template&id=5f7194ae&scoped=true"
import script from "./Results.vue?vue&type=script&lang=js"
export * from "./Results.vue?vue&type=script&lang=js"
import style0 from "./Results.vue?vue&type=style&index=0&id=5f7194ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7194ae",
  null
  
)

export default component.exports