<template>
  <div class="test-register-member wrap960 space-100">
    <h1>{{ categoryData.name }}の記事 追加・編集</h1>
    <div class="record-box">
      <div class="navi">
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="articlesTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
        全{{ articlesTotal }}件
      </div>
      <div
        class="item-box-cover"
        v-for="articleData in articleList"
        :key="articleData.id"
      >
        <div class="member-list">
          <p>{{ articleData.title }}</p>
          <div class="button-cover2">
            <router-link
              :to="`/ctrl/article/${categoryId}/article-detail/${articleData.id}`"
            >
              <el-button
                type="primary"
                style="vertical-align: top; height: 100%; margin-right: 10px"
                >確認・修正</el-button
              ></router-link
            >
            <el-button @click="deleteArticle(articleData.id)">削除</el-button>
          </div>
        </div>
      </div>
      <div class="navi">
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="articlesTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
      </div>
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <router-link :to="`/ctrl/article/${categoryId}/article-detail/0`">
          <el-button type="danger" class="new-button"
            >新規作成</el-button
          ></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrl-product-products",
  components: {},
  props: {
    categoryId: String,
  },
  data() {
    return {
      articleList: [{ id: "", title: "" }],
      pageSize: 10, //表示させたい数
      pagerCount: 7, //見えるページの数
      currentPage: 1, //そのページ
      articlesTotal: 0, //全記事数
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
  watch: {
    currentPage(newCurrentPage) {
      this.getArticleList(this.categoryId, newCurrentPage, this.pageSize);
    },
  },
  created() {
    this.getArticleList(this.categoryId, 1, this.pageSize);
  },
  methods: {
    getArticleList(categoryId, currentPage, pageSize) {
      this.$axios
        .get(
          `${this.rootUrl}/api/article/get-article-list?category_id=${this.categoryId}&current_page=${currentPage}&page_size=${pageSize}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.articleList = response.data.articleList;
            this.articlesTotal = response.data.articlesTotal;
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteArticle(articleId) {
      if (confirm("本当に削除してもいいですか？")) {
        let params = new URLSearchParams();
        params.append("article_id", articleId);
        this.$axios
          .post(`${this.rootUrl}/api/article/delete-article`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.getArticleList(
                this.categoryId,
                this.currentPage,
                this.pageSize
              );
              return;
            } else if (response.data.status === 403) {
              alert(response.data.message);
              return;
            } else {
              alert("エラーが発生しました。");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.record-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  .sub-title {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
    img {
      width: 50px;
      vertical-align: middle;
      margin-right: 10px;
    }
    span {
      font-weight: 700;
      vertical-align: middle;
      font-size: 32px;
    }
  }
  .item-box-cover {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .member-list {
    .button-cover2 {
      display: flex;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
        margin-top: 20px;
      }
    }
    background-color: #f7f7f7;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    p {
      width: 100%;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .explanation {
    // background-color: var(--yellow2);
    // border-bottom: 2px var(--yellow2) solid;
    width: 40%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
</style>