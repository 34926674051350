<template>
  <div class="article-register wrap960 space-100">
    <h1>記事の追加・編集</h1>
    <div class="cover">
      <div class="row">
        <p class="title">タイトル</p>
        <input v-model="articleData.title" type="text" class="input" />
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.title"
        style="text-align: right"
      />

      <div class="row">
        <p class="title">内容</p>
        <textarea v-model="articleData.text" type="text" class="input text" />
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.text"
        style="text-align: right"
      />

      <div class="row">
        <p class="title">掲載商品</p>
        <el-select
          v-model="articleData.productIdList"
          multiple
          placeholder="選択してください"
          class="input"
        >
          <el-option
            v-for="(productData, index) in categoryData.productList"
            :key="index"
            :label="productData.title + productData.article"
            :value="productData.id"
          >
          </el-option>
        </el-select>
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.productIdList"
        style="text-align: right"
      />
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <el-button @click="registerArticleData" type="primary">登録</el-button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import DiplayValidationErrorMessages from "@/components/Ui/DisplayValidationErrorMessages.vue";

export default {
  name: "test-register-article",
  components: {
    DiplayValidationErrorMessages,
  },
  props: {
    categoryId: String, // propsで来ている想定
    articleId: String, // propsで来ている想定（0なら新規追加。それ以外は編集。）
  },
  data() {
    return {
      articleData: {
        title: "",
        text: "",
        sort: 0,
        productIdList: [],
      },
      validationErrorData: {
        categoryId: [],
        articleId: [],
        title: [],
        text: [],
        sort: [],
        productIdList: [],
      },
        // loading: true
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "categoryList"]),
    categoryData() {
      this.$store.getters.categoryData(this.categoryId).productList.forEach(product => {
        product.article = "";
        product.articles.forEach(article => {
          product.article += " [" + article.title + "]";
        });
      });
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
  watch: {
    articleId: {
      handler() {
        if (this.articleId === "0") {
          //
        }else{
          this.$axios
            .get(
              `${this.rootUrl}/api/article/get-article-data?article_id=${this.articleId}`
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === 200) {
                this.articleData = response.data.articleData;
                // this.loading = false;
                return;
              } else if (
                response.data.status === 401 ||
                response.data.status === 400
              ) {
                alert(response.data.message);
                return;
              } else {
                alert("エラーが発生しました。");
                return;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      immediate: true,
    },
  },
  methods: {
    registerArticleData() {
      let params = new URLSearchParams();
      params.append("category_id", this.categoryId);
      params.append("article_id", this.articleId);
      params.append("article_data", JSON.stringify(this.articleData));
      this.$axios
        .post(`${this.rootUrl}/api/article/register-article-data`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch('setCategoryList');
            this.validationErrorData = {};
            this.$router.go(-1);
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
        back() {
      this.$router.go(-1);
    },

  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 50px;
  text-align: center;
  background-color: #f7f7f7;
  padding: 20px 0;
}
.cover {
  width: 100%;
  padding-bottom: 100px;
  margin: 0 auto;
  .row {
    display: flex;
    margin: 20px 0;
        @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .title {
      width: 200px;
    }
    .input {
      width: calc(100% - 200px);
      font-size: 16px;
      padding-left: 10px;
      @media screen and(max-width: 767px) {
        width: 95%;
        font-size: 17px;
      }
    }
    .text {
      min-height: 300px;
      padding: 10px;
    }
  }
}

</style>