<template>
  <div class="home-strong-item">
    <div class="sub-title space-100" v-if="categoryId === '1'">
      <img :src="`${rootUrl}/img/record03.png`" alt="レコードイラスト" />
      <span>クラシックレコード</span>
    </div>
    <div class="sub-title space-100" v-else-if="categoryId === '2'">
      <img :src="`${rootUrl}/img/record04.png`" alt="レコードイラスト4" />
      <span>ジャズレコード</span>
    </div>
    <div class="sub-title space-100" v-else-if="categoryId === '9'">
      <img :src="`${rootUrl}/img/record03.png`" alt="レコードイラスト" />
      <span>洋楽・邦楽レコード</span>
    </div>
    <div class="sub-title space-100" v-else-if="categoryId === '3'">
      <img :src="`${rootUrl}/img/record05.png`" alt="レコードイラスト" />
      <span>オープンリール</span><br class="sp-only" /><span class="mini-font"
        >（デッキ・テープ・リールなど）</span
      >
    </div>
    <div class="sub-title space-100" v-else-if="categoryId === '7'">
      <img :src="`${rootUrl}/img/record02.png`" alt="レコードイラスト" />
      <span>オーディオ機器</span>
    </div>

    <div class="record-box">
      <UiHomeItemBox
        v-for="box in boxes.slice(0, 8)"
        :key="box.id"
        :imgpath="box.imgPath"
        :name="box.name"
        :title="box.title"
        :description="box.text"
        :price="box.price"
        class="item-box"
      />
    </div>

    <div>
      <div @click="display = !display">
        <div class="more-button" v-show="!display && boxes.length >= 9">
          <span>買取強化品目をもっと見る</span>
        </div>
      </div>

      <transition-group name="opacity">
        <div class="record-box2" key="index" v-if="display">
          <span v-for="box in boxes.slice(8)" :key="box.id" class="item-box">
            <UiHomeItemBox
              :boxes="boxes"
              :imgpath="box.imgPath"
              :name="box.name"
              :title="box.title"
              :description="box.text"
              :price="box.price"
              class="in-item-box"
            />
          </span>
        </div>
      </transition-group>
    </div>
    <div @click="display = !display">
      <div class="close-button" v-show="display">
        <span>閉じる</span>
      </div>
    </div>
    <!-- </div> -->
    <div class="center space-50">
      <p class="text" v-if="categoryId === '1'">
        リアリーグッドはクラシックのジャンルを主力に取り扱っている数少ないレコード店です。<br />輸入盤、国内盤問わず専門スタッフがしっかりと買取査定させて頂きますので他店で断れたケースも是非ご相談ください。
      </p>
      <p class="text" v-else-if="categoryId === '2'">
        ジャズのレコードはオリジナル盤など専門性の高いレコードが多く、鑑定力が必須です。<br />リアリー
        グッドではジャズのレコードに精通した専門スタッフが一枚一枚丁寧に買取査定いたします。
        輸入再発盤や国内盤も納得の金額をご提示させて頂きます。
      </p>
      <p class="text" v-else-if="categoryId === '9'">
        近年再評価がされている昭和歌謡、シティポップからJ-POPなど邦楽のレコードもしっかり査定、買取させて頂きます。ロックやポップスのレコードも精通したスタッフがオリジナル初期盤から国内盤まで丁寧に査定、買取いたします。
      </p>
      <p class="text" v-else-if="categoryId === '3'">
        オープンリールのデッキ・テープ共にどこよりも高い金額をご提示させて頂きます。<br />オープンリールテープの販売実績は国内有数を誇ります。
        関連用品から付属するもの、オープンリールのことなら何でもご相談ください。使用済・録音済のテープ（生テープ）、リールのみも買取しています。
      </p>
      <p class="text" v-else-if="categoryId === '7'">
        レコードプレーヤー、アンプ、スピーカーなど、オーディオ類もソフトとまとめて買取致します。動かないものやメンテナンスが必要なものもしっかり査定致します。大型の重たくて動かせないスピーカーやアンプの査定、大量引取も対応可能です。
      </p>
      <div
        v-if="
          categoryId === '1' ||
          categoryId === '2' ||
          categoryId === '3' ||
          categoryId === '9'
        "
      >
        <router-link :to="`${linkPath}`">
          <img
            :src="`${rootUrl}/img/button01.png`"
            alt="もっと見る"
            class="more"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHomeItemBox from "@/components/Ui/Home/ItemBox.vue";
export default {
  name: "home-strong-item-classic",
  components: {
    UiHomeItemBox,
  },
  props: {
    categoryId: String, // 本当はこっち
    linkPath: String,
  },
  data() {
    return {
      display: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),

    // このコメントアウトを外すとTOP表示商品一覧がとれる
    boxes() {
      return this.$store.getters.topProductList(this.categoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-strong-item {
  .sub-title {
    img {
      width: 50px;
      vertical-align: middle;
      margin-right: 10px;
    }
    span {
      font-weight: 700;
      vertical-align: middle;
      font-size: 32px;
    }
    .mini-font {
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .center {
    flex-direction: column;
    .text {
      font-weight: 700;
    }
    .more {
      width: 350px;
      margin: 50px 0;
    }
    a:hover {
      opacity: 0.8;
    }
  }
  .record-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item-box {
      width: 24%;

      @media screen and (max-width: 787px) {
        width: 48%;
      }
    }
  }
  .record-box::before {
    content: "";
    display: block;
    width: 24%;
    order: 1;
  }
  .record-box::after {
    content: "";
    display: block;
    width: 24%;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
.record-box2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item-box {
    width: 24%;

    @media screen and (max-width: 787px) {
      width: 48%;
    }
  }
  .in-item-box {
    width: 100%;
  }
}
.record-box2::before {
  content: "";
  display: block;
  width: 24%;
  order: 1;
}
.record-box2::after {
  content: "";
  display: block;
  width: 24%;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.more-button,
.close-button {
  border-radius: 10px;
  text-align: center;
  width: 80%;
  margin: 15px auto;
  background-color: var(--orange);
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.more-button {
  background-color: var(--orange);
}
.close-button {
  background-color: white;
  color: var(--orange);
  border: 2px solid var(--orange);
  width: 50%;
}
.opacity-enter {
  opacity: 0;
}
.opacity-enter-active {
  transition: opacity 500ms;
}
.opacity-enter-to {
  opacity: 1;
}
.opacity-leave {
  opacity: 1;
}
.opacity-leave-active {
  transition: opacity 500ms;
}
.opacity-leave-to {
  opacity: 0;
}
</style>