import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        rootUrl: 'https://reallygood-kaitori.com',
        categoryList: [],
        resultList: [],
        prefList: [],
        buyingTypeList: [],
    },
    getters: {
        rootUrl(state) {
            return state.rootUrl
        },
        login(state) {
            return state.login
        },
        categoryList(state) {
            return state.categoryList;
        },
        selectedCategoryList: (state) => (categoryIdList) => {
            let selectedCategoryList = [];
            const categoryList = state.categoryList;
            categoryList.forEach(categoryData => {
                categoryIdList.forEach(categoryId => {
                    if (categoryData.id === categoryId) {
                        selectedCategoryList.push(categoryData);
                    } else {
                        // continue;
                    }
                });
            });
            return selectedCategoryList;
        },
        categoryData: (state) => (categoryId) => {
            return state.categoryList.find(categoryData => categoryData.id === categoryId);
        },
        resultList(state) {
            return state.resultList;
        },
        topResultList(state) {
            return state.resultList.filter(resultData => resultData.visible === true);
        },
        resultData: (state) => (resultId) => {
            return state.resultList.find(resultData => resultData.id === resultId);
        },
        productList: (state, getters) => (categoryId) => {
            let categoryData = getters.categoryData(categoryId);
            return categoryData.productList;
        },
        topProductList: (state, getters) => (categoryId) => {
            let productList = getters.productList(categoryId);
            return productList.filter(resultData => resultData.visible === true);
        },
        productData: (state, getters) => (categoryId, productId) => {
            let productList = getters.productList(categoryId);
            return productList.find(productData => productData.id === productId);
        },
        groupData: (state, getters) => (categoryId, groupId) => {
            let categoryData = getters.categoryData(categoryId);
            return categoryData.groupList.find(groupData => groupData.id === groupId);
        },
        memberData: (state, getters) => (categoryId, groupId, memberId) => {
            let groupData = getters.groupData(categoryId, groupId);
            return groupData.memberList.find(memberData => memberData.id === memberId);
        },
        prefList(state) {
            return state.prefList;
        },
        prefData: (state) => (prefCode) => { // 「prefCode」という名前は国土交通省のに合わせてます
            return state.prefList.find(prefData => prefData.prefCode === prefCode);
        },
        buyingTypeList(state) {
            return state.buyingTypeList;
        },
        buyingTypeData: (state) => (buyingTypeId) => {
            return state.buyingTypeList.find(buyingTypeData => buyingTypeData.id === buyingTypeId);
        },
    },
    mutations: {
        setRootUrl(state, payload) {
            state.rootUrl = payload;
        },
        setPrefList(state, payload) {
            state.prefList = payload;
        },
        setBuyingTypeList(state, payload) {
            state.buyingTypeList = payload;
        },
        setCategoryList(state, payload) {
            state.categoryList = payload;
        },
        setResultList(state, payload) {
            state.resultList = payload;
        },
        setLogin(state, payload) {
            state.login = payload;
        },
    },
    actions: {
        setRootUrl({
            commit
        }) {
            let rootUrl = process.env.NODE_ENV === 'production' ? "https://reallygood-kaitori.com" : "http://localhost:8001";
            commit('setRootUrl', rootUrl);
        },
        setGeneralData({
            getters,
            commit
        }) {
            axios
                .get(`${getters.rootUrl}/api/get-general-data`)
                .then((response) => {
                    console.log(response);
                    if (response.data.status === 200) {
                        commit('setPrefList', response.data.prefList);
                        commit('setBuyingTypeList', response.data.buyingTypeList);
                        return;
                    } else {
                        alert("エラーが発生しました。");
                        return;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setCategoryList({
            getters,
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${getters.rootUrl}/api/category/get-category-list`)
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === 200) {
                            commit('setCategoryList', response.data.categoryList);
                        } else {
                            alert("エラーが発生しました。");
                        }
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            })
        },
        setResultList({
            getters,
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${getters.rootUrl}/api/result/get-result-list`)
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === 200) {
                            commit('setResultList', response.data.resultList);
                        } else {
                            alert("エラーが発生しました。");
                        }
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            })
        },
        setLogin({
            commit
        }, payload) {
            commit('setLogin', payload);
        },
        loginCheck({
            getters
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${getters.rootUrl}/api/auth/auth-check`)
                    .then(res => {
                        console.log(res.data);
                        //[status: 200, message: "ログイン失敗"]
                        resolve(res);
                        // resolve(res.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            })
        },
    },
    modules: {},
    plugins: [createPersistedState()]
})
