<template>
  <div class="article-promise">
    <div class="title-cover">
      <img
        :src="`${rootUrl}/img/title07.png`"
        alt="リアリーグッドからのお約束"
      />
    </div>

    <p>
      レコード・オープンリール・ヴィンテージオーディオの買取は、私たちリアリーグッドに是非お任せください。レコードに愛を持った専門店による買取だからこそ実現できる、専門的で適切な査定と買取をお約束します。<br />思い出の品、大事なコレクションも安心してご依頼ください。<br />そして、買い取らせていただいたレコードは丁寧にクリーニングし、次のお客様へ責任をもって引き継がせていただきます。<br />まずはお気軽に、お電話（フリーダイヤル）、または、メールで問い合わせくださいませ。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "home-promise",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.article-promise {
  margin-bottom: 150px;
  p {
    font-size: 20px;
    margin-top: 100px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
            @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
  }

  .contact {
    display: flex;
    justify-content: space-between;
    .button {
      -webkit-animation: blink 1.5s ease-in-out infinite alternate;
      -moz-animation: blink 1.5s ease-in-out infinite alternate;
      animation: blink 1.5s infinite alternate;
      width: 47%;
      a {
        img {
          width: 100%;
        }
      }
    }
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>