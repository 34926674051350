var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-about-us"},[_c('div',{staticClass:"back",style:(`background-image: url(${_vm.rootUrl}/img/back-img01.jpg);`)},[_c('div',{staticClass:"title-cover wrap960"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/title06.png`,"alt":"わたしたちリアリーグッドとは"}})])]),_vm._m(0),_c('div',{staticClass:"wrap1100 space-50",attrs:{"id":"shop-info"}},[_vm._m(1),_c('div',{staticClass:"image-list"},_vm._l((_vm.firstShopImg),function(imge){return _c('div',{key:imge.id,staticClass:"about-us-image"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/${imge.imgpath}`,"alt":imge.name}})])}),0),_vm._m(2),_c('div',{staticClass:"image-list"},_vm._l((_vm.secondShopImg),function(imge){return _c('div',{key:imge.id,staticClass:"about-us-image"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/${imge.imgpath}`,"alt":imge.name}})])}),0),_vm._m(3),_c('UiBoxService',{attrs:{"imgpath":`service01.jpg`,"name":`クリーニングイメージ 
    `,"title":`超音波機器による<br>本格的なクリーニング`,"des":`一般的な店舗では汚れをクロスで拭き上げるなど手作業での清掃ですが、リアリーグッドでは超音波洗浄機など本格的な機器を使用したクリーニングを行っています。 <br>当店で買い取った商品はすべてこのマシーンでクリーニングし、キレイな状態で店頭に並びます。`}}),_c('UiBoxService',{attrs:{"imgpath":`service02.jpg`,"name":`クリーニングイメージ 
    `,"title":`レコードの反り直し<br>サービスも行っています`,"des":`リアリーグッドには、レコードの反り直しができる機器まであります。この機器を持っているショップは日本でほとんどないのではないでしょうか。レコードの反り直しについては、うまくできる場合とそうでない場合があるため、リアリーグッドでは、お客様に無料サービスとして提供しています。`}}),_c('UiBoxService',{attrs:{"imgpath":`service03.jpg`,"name":`クリーニングイメージ 
    `,"title":`オープンリールテープの<br>再生チェック`,"des":`買い取ったオープンリールテープは、全て再生チェックをし、テープの欠損や劣化がないかを確認しています。再生不良のあるものは販売しておりません。<br>オークションなどで売られているミュージックテープは録音内容の未確認な物が多いため、当店では安心してご購入いただくことができます。`}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"space-100 wrap960"},[_vm._v(" レコードやCDなどの音楽ソフトを中心に、オーディオ機器や関連パーツまで、音楽に関連する品の買取・販売を行っております。 音楽専門店として、豊富な商品知識と顧客ネットワークを活かした販売力が強みです。"),_c('br'),_vm._v(" 買取は全国を対象に宅配、出張、店頭で受け付けております。貴重なお品はいつでもご相談、どこにでも査定に伺います。実店舗も営業しております。お持ち込みも大歓迎です。"),_c('br'),_vm._v(" お売り頂いた大切なお品は、店舗とインターネット通販を通して次のオーナーにしっかり繋げられるよう、専門店として責任ある販売を心掛けております。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"access-cover"},[_c('div',{staticClass:"map"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.1372050752743!2d136.90260341558633!3d35.153198780320864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003776ed2e36ef9%3A0x68237c03ae9b7e9a!2z44Oq44Ki44Oq44O844Kw44OD44OJ!5e0!3m2!1sja!2sjp!4v1652162457277!5m2!1sja!2sjp","width":"100%","height":"484px","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})]),_c('table',{staticClass:"access-outer"},[_c('tr',[_c('th',[_vm._v("店舗名")]),_c('td',[_vm._v("Really Good 名古屋店"),_c('br'),_c('span',{staticStyle:{"margin-left":"-10px"}},[_vm._v("（リアリーグッド 名古屋店）")])])]),_c('tr',[_c('th',[_vm._v("TEL")]),_c('td',[_c('table',{staticClass:"access-inner"},[_c('tr',[_c('th',[_vm._v("店舗：")]),_c('td',[_vm._v("052-211-7931")])]),_c('tr',[_c('th',[_vm._v("買取専用：")]),_c('td',[_vm._v("0120-590-510")])])])])]),_c('tr',[_c('th',[_vm._v("住所")]),_c('td',[_vm._v("愛知県名古屋市中区橘1-27-6"),_c('br'),_vm._v("石橋ビル2F")])]),_c('tr',[_c('th',[_vm._v("営業時間")]),_c('td',[_vm._v("12:00〜19:00 年中無休")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"access-cover"},[_c('div',{staticClass:"map"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13080.84737588505!2d137.1641828!3d34.9512987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6004bd7be4fa692f%3A0xc946b122e23ba841!2z44Oq44Ki44Oq44O844Kw44OD44OJ5bKh5bSO5bqX!5e0!3m2!1sja!2sjp!4v1705283368914!5m2!1sja!2sjp","width":"100%","height":"484px","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})]),_c('table',{staticClass:"access-outer"},[_c('tr',[_c('th',[_vm._v("店舗名")]),_c('td',[_vm._v("Really Good 岡崎店"),_c('br'),_c('span',{staticStyle:{"margin-left":"-10px"}},[_vm._v("（リアリーグッド 岡崎店）")])])]),_c('tr',[_c('th',[_vm._v("TEL")]),_c('td',[_c('table',{staticClass:"access-inner"},[_c('tr',[_c('th',[_vm._v("店舗：")]),_c('td',[_vm._v("050-6883-9131")])]),_c('tr',[_c('th',[_vm._v("買取専用：")]),_c('td',[_vm._v("0120-590-510")])])])])]),_c('tr',[_c('th',[_vm._v("住所")]),_c('td',[_vm._v("愛知県岡崎市明大寺町西郷中 32-35"),_c('br'),_vm._v("メビウスビル 1F")])]),_c('tr',[_c('th',[_vm._v("営業時間")]),_c('td',[_vm._v("12:00〜18:30 定休日：水、日")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-title"},[_c('p',[_vm._v(" レコード・オーディオ好きが高じて始めた専門店。"),_c('br'),_vm._v("日本でも有数なこだわりあるサービスを行なっています。 ")])])
}]

export { render, staticRenderFns }