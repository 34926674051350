<template>
  <div class="register-member wrap960 space-50">
    <h1>掲載情報 追加・編集</h1>

    <div class="cover">
      <div class="row" style="margin-bottom:0;">
        <p class="title">タイトル</p>
        <input v-model="memberData.name" type="text" class="input" />
      </div>
            <div style="text-align: right">
        <p :class="color2(memberData.name.length)">
        残り{{ remaining2 }}文字
        </p>
        </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.name"
        style="text-align: right"
      />

      <div class="row" style="margin-bottom:0;">
        <p class="title">自由入力欄</p>
        <textarea v-model="memberData.text" type="text" class="input text" />
      </div>
            <div style="text-align: right">
        <p :class="color(memberData.text.length)">残り{{ remaining }}文字</p>
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.text"
        style="text-align: right"
      />

      <div class="row">
        <p class="title">リンク先記事</p>
        <select
          type="text"
          name="article"
          v-model="memberData.articleId"
          style="width: 90%"
        >
          <option
            v-for="articleData in articleList"
            :key="articleData.id"
            :value="articleData.id"
          >
            {{ articleData.title }}
          </option>
        </select>
      </div>

      <!-- ↓↓ この書き方でアップロードした画像表示できるよ ↓↓ -->
      <img :src="`${rootUrl}/storage/${memberData.imgPath}`" />
      <!-- ↑↑ この書き方でアップロードした画像表示できるよ ↑↑ -->

      <div class="row" style="display: flex; align-items: center">
        <span style="margin-right: 10px">変更後</span>
        <input type="file" @change="fileSelected" />
      </div>
      <DiplayValidationErrorMessages
        :messageList="validationErrorData.uploadFile"
        style="text-align: right"
      />
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <el-button @click="registerMemberData" type="primary">登録</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiplayValidationErrorMessages from "@/components/Ui/DisplayValidationErrorMessages.vue";

export default {
  name: "register-member",
  components: {
    DiplayValidationErrorMessages,
  },
  props: {
    groupId: String, // propsで来ている想定
    memberId: String, // propsで来ている想定（0なら新規追加。それ以外は編集。）
    categoryId: String,
  },
  data() {
    return {
      memberData: {
        name: "",
        text: "",
        imgPath: "images/default.jpg", // アップロード機能で記入される想定
        articleId: "",
        sort: 0,
      },
      uploadFile: null,
      validationErrorData: {
        groupId: [],
        memberId: [],
        name: [],
        text: [],
        articleId: [],
        sort: [],
      },
      articleList: [],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
        remaining: function () {
      return 78 - this.memberData.text.length;
    },
    remaining2: function () {
      return 18 - this.memberData.name.length;
    },
  },
  watch: {
    memberId: {
      handler() {
        if (this.memberId === "0") {
          // そのまんま
        } else {
          this.memberData = JSON.parse(
            JSON.stringify(
              this.$store.getters["memberData"](
                this.categoryId,
                this.groupId,
                this.memberId
              )
            )
          );
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$axios
      .get(
        `${this.rootUrl}/api/article/get-article-list?category_id=${this.categoryId}&current_page=1&page_size=99999`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          this.articleList = response.data.articleList;
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
          color(memberDataTextLength) {
      if ( memberDataTextLength <= 78) {
        return "black";
      }
      if (memberDataTextLength > 78) {
        return "red";
      }
      return "black";
    },
    color2(memberDataNameLength){
      if ( memberDataNameLength <= 1) {
        return "black";
      }
      if (memberDataNameLength > 18) {
        return "red";
      }
      return "black";
    },
    fileSelected(event) {
      this.uploadFile = event.target.files[0];
    },
    registerMemberData() {
       if (this.memberData.text.length <= 78 && this.memberData.name.length <= 18) {
      const formData = new FormData();
      formData.append("group_id", this.groupId);
      formData.append("member_id", this.memberId);
      formData.append("member_data", JSON.stringify(this.memberData));
      formData.append("upload_file", this.uploadFile);
      this.$axios
        .post(`${this.rootUrl}/api/member/register-member-data`, formData)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.go(-1);
            this.validationErrorData = {};
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
              } else {
        alert("タイトルは18文字以内、自由入力欄は78文字以内でおかきください。");
        return;
      }
    },
        back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 50px;
  background-color: #f7f7f7;
  padding: 20px 0;
}

.cover {
  width: 100%;
  padding-bottom: 100px;
  .row {
    display: flex;
    margin: 20px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    // width: 100%;
    .title {
      width: 200px;
    }
    .input {
      width: 90%;
      font-size: 16px;
      @media screen and(max-width: 767px) {
        width: 95%;
        font-size: 17px;
      }
    }
    .text {
      min-height: 300px;
      padding: 10px;
    }
  }
}
.black {
color: black;
}
.red {
  color: red;
}

</style>