<template>
  <div class="test-logout">
    <p><el-button @click="logout" type="info" plain>ログアウト</el-button></p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "test-logout",
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    logout() {
      this.$axios
        .get(`${this.rootUrl}/api/auth/logout`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.push("/ctrl/login")
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>