<template>
  <div id="app">
    <Header v-show="$route.meta.ctrl !== true"/>
    <router-view :class="{ 'body-top': $route.meta.ctrl !== true }" />
    <ToTopButton :path="path" v-show="$route.meta.ctrl !== true"/>
    <Footer :path="path" v-show="$route.meta.ctrl !== true"/>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@500&family=Noto+Sans:wght@400;700&display=swap");

#app {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  color: black;
  @media screen and (max-width: 768px){
    font-size: 18px;
  }
}
:root {
  --orange: #f05a24;
  --green: #39b44a;
  --yellow: #ffff00;
  --yellow2: #fed400;
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
.body-top {
  padding-top: 123px;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}
.wrap1100,
.wrap960 {
  width: 100vw; //画面幅に対して、基本100%
  margin: 0 auto;
  line-height: 2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.wrap1100 {
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
}
.wrap960 {
  max-width: 960px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
}
.pc-kaigyo,.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.tb-kaigyo {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.tb-kaigyo-only {
  @media screen and (min-width:1025px) and ( max-width:1100px){
   display: none;
  }
}
.sp-kaigyo,.sp-only {
  display: none;

  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}



h1,
h2 {
  font-size: 28px;
  // font-weight: 700;
  text-align: center;
}
.space-50 {
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
}
.space-100 {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
}
.space-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}

.center,
.center-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-c {
  flex-direction: column;
}
.title-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  img {
    width: 60%;
 @media screen and (max-width: 768px){
        width: 100%;
      }
  }
}
  .button-cover {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    @media screen and(max-width: 767px) {
      flex-direction: column-reverse;
      align-items: center;

    }

    .el-button {
      margin-left: 30px;
      height: 40px;
      vertical-align: top;
      @media screen and(max-width: 767px) {
        margin: 0;
        width: 150px;
        margin-top: 10px;
        margin-left: 0!important;

      }
    }
  }
</style>
