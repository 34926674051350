<template>
  <div class="ctrl-change-password">
    <h1>パスワード変更</h1>
    <div class="panel">
      <div class="row">
        <p class="title">現パスワード</p>
        <p>
          <input v-model="oldPassword" type="password" />
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.oldPassword"
          />
        </p>
      </div>
      <div class="row">
        <p class="title">新パスワード</p>
        <p>
          <input v-model="newPassword" type="password" />
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.newPassword"
          />
        </p>
      </div>
      <div class="row">
        <p class="title">新パスワード（確認用）</p>
        <p>
          <input v-model="newPassword2" type="password" />
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.newPassword2"
          />
        </p>
      </div>
      <div class="button-cover">
        <el-button @click="back" type="info">戻る</el-button>
      <el-button @click="changePassword" type="primary">変更</el-button>
    </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiplayValidationErrorMessages from "@/components/Ui/DisplayValidationErrorMessages.vue";

export default {
  name: "ctrl-change-password",
  components: {
    DiplayValidationErrorMessages,
  },
  props: {},
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
      validationErrorData: {
        oldPassword: [],
        newPassword: [],
        newPassword2: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    changePassword() {
      let params = new URLSearchParams();
      params.append("old_password", this.oldPassword);
      params.append("new_password", this.newPassword);
      params.append("new_password2", this.newPassword2);
      this.$axios
        .post(`${this.rootUrl}/api/auth/change-password`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert("変更しました");
               this.$router.push("/ctrl")
            this.validationErrorData = {};
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert("変更できませんでした。");
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back(){
       this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss" scoped>
.panel {
  // height: 400px;
  width: 100%;
  background-color: #f5f5f5;
  padding: 100px;
  margin-bottom: 50px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and(max-width: 767px) {
    padding: 100px 0;
  }
  .row {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
     @media screen and(max-width: 767px){
       flex-direction: column;
       align-items: center;
     }
    p{
      width: calc(100% - 300px);
       @media screen and(max-width: 767px){
         width: 90%;
       }
    }
    input {
    width: 100%;
    height: 30px;
    }
      .title{
        width: 300px;
      }
  }
  .button-cover {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @media screen and(max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .el-button {
      margin-left: 30px;
      height: 40px;
      vertical-align: top;
      @media screen and(max-width: 767px) {
        margin: 0;
        width: 150px;
        margin-top: 10px;
      }
    }
  }
}

</style>