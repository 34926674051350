product<template>
  <div class="ctrl-login">
      <CtrlResults/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlResults from "@/components/Ctrl/Results.vue";
export default {
  name: "ctrl-results",
  components: {
    CtrlResults,
  },
  props: {

  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
