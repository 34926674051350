<template>
  <div class="home-about-us">
    <div
      class="back"
      :style="`background-image: url(${rootUrl}/img/back-img01.jpg);`"
    >
      <div class="title-cover wrap960">
        <img
          :src="`${rootUrl}/img/title06.png`"
          alt="わたしたちリアリーグッドとは"
        />
      </div>
    </div>
    <p class="space-100 wrap960">
      レコードやCDなどの音楽ソフトを中心に、オーディオ機器や関連パーツまで、音楽に関連する品の買取・販売を行っております。
      音楽専門店として、豊富な商品知識と顧客ネットワークを活かした販売力が強みです。<br />
      買取は全国を対象に宅配、出張、店頭で受け付けております。貴重なお品はいつでもご相談、どこにでも査定に伺います。実店舗も営業しております。お持ち込みも大歓迎です。<br />
      お売り頂いた大切なお品は、店舗とインターネット通販を通して次のオーナーにしっかり繋げられるよう、専門店として責任ある販売を心掛けております。
    </p>

    <div class="wrap1100 space-50" id="shop-info">
      <div class="access-cover">
        <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.1372050752743!2d136.90260341558633!3d35.153198780320864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003776ed2e36ef9%3A0x68237c03ae9b7e9a!2z44Oq44Ki44Oq44O844Kw44OD44OJ!5e0!3m2!1sja!2sjp!4v1652162457277!5m2!1sja!2sjp" width="100%" height="484px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <table class="access-outer">
          <tr>
            <th>店舗名</th>
            <td>Really Good 名古屋店<br /><span style="margin-left: -10px;">（リアリーグッド 名古屋店）</span></td>
          </tr>
          <tr>
            <th>TEL</th>
            <td>
              <table class="access-inner">
                <tr>
                  <th>店舗：</th>
                  <td>052-211-7931</td>
                </tr>
                <tr>
                  <th>買取専用：</th>
                  <td>0120-590-510</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <th>住所</th>
            <td>愛知県名古屋市中区橘1-27-6<br />石橋ビル2F</td>
          </tr>
          <tr>
            <th>営業時間</th>
            <td>12:00〜19:00 年中無休</td>
          </tr>
        </table>
      </div>

      <div class="image-list">
        <div class="about-us-image" v-for="imge in firstShopImg" :key="imge.id">
          <img :src="`${rootUrl}/img/${imge.imgpath}`" :alt="imge.name" />
        </div>
      </div>

      <div class="access-cover">
        <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13080.84737588505!2d137.1641828!3d34.9512987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6004bd7be4fa692f%3A0xc946b122e23ba841!2z44Oq44Ki44Oq44O844Kw44OD44OJ5bKh5bSO5bqX!5e0!3m2!1sja!2sjp!4v1705283368914!5m2!1sja!2sjp" width="100%" height="484px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <table class="access-outer">
          <tr>
            <th>店舗名</th>
            <td>Really Good 岡崎店<br /><span style="margin-left: -10px;">（リアリーグッド 岡崎店）</span></td>
          </tr>
          <tr>
            <th>TEL</th>
            <td>
              <table class="access-inner">
                <tr>
                  <th>店舗：</th>
                  <td>050-6883-9131</td>
                </tr>
                <tr>
                  <th>買取専用：</th>
                  <td>0120-590-510</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <th>住所</th>
            <td>愛知県岡崎市明大寺町西郷中 32-35<br />メビウスビル 1F</td>
          </tr>
          <tr>
            <th>営業時間</th>
            <td>12:00〜18:30 定休日：水、日</td>
          </tr>
        </table>
      </div>

      <div class="image-list">
        <div class="about-us-image" v-for="imge in secondShopImg" :key="imge.id">
          <img :src="`${rootUrl}/img/${imge.imgpath}`" :alt="imge.name" />
        </div>
      </div>

      <div class="sub-title">
        <p>
          レコード・オーディオ好きが高じて始めた専門店。<br />日本でも有数なこだわりあるサービスを行なっています。
        </p>
      </div>

      <UiBoxService
        :imgpath="`service01.jpg`"
        :name="`クリーニングイメージ 
      `"
        :title="`超音波機器による<br>本格的なクリーニング`"
        :des="`一般的な店舗では汚れをクロスで拭き上げるなど手作業での清掃ですが、リアリーグッドでは超音波洗浄機など本格的な機器を使用したクリーニングを行っています。 <br>当店で買い取った商品はすべてこのマシーンでクリーニングし、キレイな状態で店頭に並びます。`"
      />
      <UiBoxService
        :imgpath="`service02.jpg`"
        :name="`クリーニングイメージ 
      `"
        :title="`レコードの反り直し<br>サービスも行っています`"
        :des="`リアリーグッドには、レコードの反り直しができる機器まであります。この機器を持っているショップは日本でほとんどないのではないでしょうか。レコードの反り直しについては、うまくできる場合とそうでない場合があるため、リアリーグッドでは、お客様に無料サービスとして提供しています。`"
      />
      <UiBoxService
        :imgpath="`service03.jpg`"
        :name="`クリーニングイメージ 
      `"
        :title="`オープンリールテープの<br>再生チェック`"
        :des="`買い取ったオープンリールテープは、全て再生チェックをし、テープの欠損や劣化がないかを確認しています。再生不良のあるものは販売しておりません。<br>オークションなどで売られているミュージックテープは録音内容の未確認な物が多いため、当店では安心してご購入いただくことができます。`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxService from "@/components/Ui/BoxService";
export default {
  name: "home-about-us",
  components: {
    UiBoxService,
  },
  props: {},
  data() {
    return {
      images: [
        { id: 1, imgpath: "about-us-image04.jpg", name: "店内写真1" },
        { id: 2, imgpath: "about-us-image01.jpg", name: "店内写真2" },
        { id: 3, imgpath: "about-us-image06.jpg", name: "店内写真3" },
        { id: 4, imgpath: "about-us-image07.jpg", name: "店内写真4" },
        { id: 5, imgpath: "about-us-image08.jpg", name: "店内写真5" },
        { id: 6, imgpath: "about-us-image09.jpg", name: "店内写真6" },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    firstShopImg() {
      return this.images.slice(0, 3);
    },
    secondShopImg() {
      return this.images.slice(3, 6);
    }
  },
};
</script>

<style lang="scss" scoped>
.home-about-us {
  .back {
    background-size: cover;
    background-position: right 10% bottom 5%;
    background-repeat: no-repeat;
    height: 640px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-size: auto 80%;
    @media screen and (max-width: 768px) {
      background-position: right 50% bottom 5%;
    }
  }

  .access-cover {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    @media screen and (min-width: 581px) and (max-width: 768px) {
      padding-bottom: 10px;
    }
    @media screen and (max-width: 768px) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 10px;
    }
    .map {
      width: 55%;

      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
    .access-outer {
      width: 40%;
      font-size: 18px;
      @media screen and (min-width: 581px) and (max-width: 768px) {
        margin-top: 20px;
        width: 70%;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
        margin-top: 20px;
      }
      tr {
        th,
        td {
          border: 1px solid black;
          vertical-align: middle;
        }
        th {
          padding: 8px 5px;
          width: 100px;
          // font-weight: 700;
        }
        td {
          padding: 20px 0 20px 30px;
          line-height: 1.3em;
          @media screen and (max-width: 768px) {
            padding: 10px;
          }
          .access-inner {
            tr {
              th,
              td {
                border: none;
                padding: 10px 0;
                font-weight: 400;
                @media screen and (max-width: 768px) {
                  display: block;
                  padding: 0;
                }
              }
              th {
                text-align: justify;
                text-align-last: justify;
                text-justify: inter-ideograph;
                width: 100px;
                text-align: left;
              }
              td {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px 0;
    @media screen and (max-width: 768px) {
      margin: 20px 0;
    }
    .about-us-image {
      width: 32%;
    }
  }
  .sub-title {
    border-left: 10px solid black;
    padding-left: 20px;
    font-size: 36px;
    font-weight: bold;
    margin-top: 150px;
    @media screen and (max-width: 768px) {
      font-size: 19px;
      margin-top: 50px;
    }
          @media screen and (min-width: 581px) and (max-width: 768px) {
       margin-left: 4%;
      }
      @media screen and (max-width: 580px) {
        margin-left: 0;
      }
  }
}
</style>