<template>
  <div class="contact wrap1100">
    <!-- <h1>お問い合わせフォーム</h1> -->
    <!-- <p style="text-align:center;">卍只今製作中卍</p> -->
    <ContactContact />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContactContact from "../components/Contact/Contact.vue";

export default {
  name: "contact",
  components: { ContactContact },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {
    //     back() {
    //   this.$router.go(-1);
    // },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 100px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}

</style>
