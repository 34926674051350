var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-how-to-buy-delivery"},[_c('div',{staticClass:"flow"},[_c('p',{staticClass:"title center"},[_vm._v("郵送・宅配買取の流れ")]),_c('p',{staticClass:"text"},[_vm._v(" 全国どこからでも買取します！箱に詰めて送るだけ！送料無料でご自宅 から発送！梱包キットもご用意しております。 ")]),_c('div',{staticClass:"flow-box"},[_c('div',{staticClass:"box center-c"},[_c('img',{staticClass:"truck",attrs:{"src":`${_vm.rootUrl}/img/mobile-service.png`,"alt":"出張買取トラック"}}),_vm._m(0)]),_c('div',{staticClass:"box center-c"},[_c('img',{staticClass:"box",attrs:{"src":`${_vm.rootUrl}/img/delivery.png`,"alt":"reallygood-ロゴ"}}),_vm._m(1)]),_c('div',{staticClass:"box center-c"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/delivery01.png`,"alt":"0円"}}),_vm._m(2)])])]),_c('UiBox',{attrs:{"type":`orange`,"title":`お電話またはメールでお問い合わせください`,"imgpath":`delivery02.jpg`,"name":``,"text":`全国どこからでも対応いたします。<br>買取専用フリーダイヤル、もしくはメールフォームよりお申込ください。<br><br><span class='big'>・0120-590-510 (受付11:00～19:00)</span><br><br>※受付時間外は留守番電話対応となります。翌営業日に折り返しにて対応させていただきます。<br>
      メールでのお問い合わせは24時間受け付けております。<br>
      買取のご相談やご質問も受け付けておりますので、お気軽にご連絡ください。`}}),_c('UiBox',{attrs:{"type":`orange`,"title":`ご依頼品を梱包`,"imgpath":`delivery03.jpg`,"name":``,"text":`ご依頼品と身分証のコピー(※)、<br>下記の宅配買取申込書を記入し同梱してください。<br><br>梱包材の指定はございませんのでお手持ちの段ボールなどをご利用ください。
      梱包材をお持ちでない方には梱包キットをご用意しておりますので、お申し込み
      の際にお伝えください。<br>梱包の際は輸送事故防止のため、緩衝材などを詰めて中身が動かないようにしてください。<br><br><span class='kome'>※身分証のコピーにつきまして<br>運転免許証、健康保険証、パスポート、学生証、住民票の写しなどの提示をお願いしております。<br>表面、現住所の記載のある面のコピーをご用意ください。</span>`}}),_c('div',{staticClass:"pdf"},[_c('a',{attrs:{"href":`${_vm.rootUrl}/pdf/REALLYGOOD_1.pdf`,"target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/delivery05.jpg`,"alt":"申込用紙"}})]),_c('p',[_vm._v(" 申込書の印刷が出来ない場合は、必要事項を手書きのメモ用紙にご記入の上ご同梱下さい。 ")])]),_c('UiBox',{attrs:{"type":`orange`,"title":`ご依頼品を発送`,"imgpath":`delivery06.jpg`,"name":`発送イメージ`,"text":`ご都合の良い運送会社へ送料無料(着払い)にてご依頼ください。`}}),_c('div',{staticClass:"pdf"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/delivery07.jpg`,"alt":"発送伝票イメージ"}}),_c('p',[_vm._v("ゆうパック着払い伝票の記入例")])]),_c('UiBox',{attrs:{"type":`orange`,"title":`査定結果をご連絡`,"imgpath":`delivery08.jpg`,"name":`査定結果イメージ`,"text":`ご依頼品が到着しましたら、専門スタッフが丁寧かつスピーディーに査定させて
いただきます。<br>結果はお電話、メールにてご連絡させていただきます。`}}),_c('UiBox',{attrs:{"type":`orange`,"title":`ご了承後、お振込でお支払い`,"imgpath":`delivery09.jpg`,"name":`お振込イメージ`,"text":`査定額のご了承を頂けましたら、当日、または翌日中にご指定の口座へお振込いたします。`}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("全国どこからでも"),_c('br'),_vm._v("買取します！")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("箱に詰めて"),_c('br'),_vm._v("送るだけ！")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("何点からでも"),_c('br'),_vm._v("送料無料")])
}]

export { render, staticRenderFns }