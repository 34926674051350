<template>
  <div class="home">
    <div class="top">
      <img
        alt="really-goodトップ"
        :src="`${rootUrl}/img/reallygood-pc-top2.jpg`"
        class="pc"
      />
      <img
        alt="really-goodトップ"
        :src="`${rootUrl}/img/reallygood-sp-top.jpg`"
        class="sp"
      />
    </div>
    <div
      class="back"
      :style="`background-image: url(${rootUrl}/img/reallygood-top-bottom.png);`"
    >
      <HomeGallery />
    </div>

    <div class="wrap960 ifyou">
      <HomeIfYouBuy />
    </div>

    <img
      alt="デザイントップ"
      :src="`${rootUrl}/img/reallygood-top3.png`"
      class="design"
    />
    <div style="background-color: #fff9e9" class="spcae3 link" id="buyback">
      <HomeAchievement />
    </div>
    <img
      alt="デザインボトム"
      :src="`${rootUrl}/img/reallygood-bottom3.png`"
      class="design"
    />

    <div class="wrap960 link strong" id="strong">
      <div class="title-cover">
        <img :src="`${rootUrl}/img/title02.png`" alt="買取強化品目" />
      </div>
      <HomeStrongItem :categoryId="`1`" :linkPath="'/classic-record'" />
      <HomeStrongItem :categoryId="`2`" :linkPath="'/jazz-record'" />
      <HomeStrongItem :categoryId="`9`" :linkPath="'/wj-music-record'" />
      <HomeStrongItem :categoryId="`3`" :linkPath="'/open-reel'" />
      <HomeStrongItem :categoryId="`7`" />
      <!-- <HomeStrongItemClassic />
      <HomeStrongItemJazz />
      <HomeStrongItemOpenreel />
      <HomeStrongItemAudio /> -->
    </div>

    <img
      alt="デザイントップ"
      :src="`${rootUrl}/img/reallygood-top3.png`"
      class="design"
    />

    <div style="background-color: #fff9e9" class="spcae3 link" id="purchase">
      <div class="wrap960">
        <HomeHowToBuy />
      </div>
    </div>
    <img
      alt="デザインボトム"
      :src="`${rootUrl}/img/reallygood-bottom3.png`"
      class="design"
    />

    <div style="background-color: #fffef7" class="spcae1">
      <div class="wrap1100" id="voice">
        <HomeCustomersVoice />
      </div>
    </div>

    <div class="wrap960 space-50" id="qa" style="padding-top: 50px">
      <HomeQa />
    </div>

    <div class="space-100" id="aboutus">
      <HomeAboutUs />
    </div>

    <div
      class="fixed-back space-150"
      :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`"
    >
      <HomeReason />
    </div>

    <div class="space-150 wrap960">
      <HomePromise />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeGallery from "@/components/Home/Gallery.vue";
import HomeIfYouBuy from "@/components/Home/IfYouBuy.vue";
import HomeAchievement from "@/components/Home/Achievement.vue";
import HomeStrongItem from "@/components/Home/StrongItem.vue";
// import HomeStrongItemClassic from "@/components/Home/StrongItem/Classic.vue";
// import HomeStrongItemJazz from "@/components/Home/StrongItem/Jazz.vue";
// import HomeStrongItemOpenreel from "@/components/Home/StrongItem/Openreel.vue";
// import HomeStrongItemAudio from "@/components/Home/StrongItem/Audio.vue";
import HomeHowToBuy from "@/components/Home/HowToBuy.vue";
import HomeCustomersVoice from "@/components/Home/CustomersVoice.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import HomeReason from "@/components/Home/Reason.vue";
import HomePromise from "@/components/Home/Promise.vue";

export default {
  name: "Home",
  components: {
    HomeGallery,
    HomeIfYouBuy,
    HomeAchievement,
    HomeStrongItem,
    // HomeStrongItemClassic,
    // HomeStrongItemJazz,
    // HomeStrongItemOpenreel,
    // HomeStrongItemAudio,
    HomeHowToBuy,
    HomeCustomersVoice,
    HomeQa,
    HomeAboutUs,
    HomeReason,
    HomePromise,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.top {
  margin: 0 auto;
  max-width: 1100px;
  width: 100vw;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }
  .pc {
    width: 95%;
    margin-top: 70px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .sp {
    display: none;
    @media screen and (max-width: 768px) {
      display: initial;
      width: 100%;
    }
  }
}
.back {
  padding-top: 10px;
  padding-bottom: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    // padding-top: 0;
    padding-bottom: 50px;
  }
}
.design {
  width: 100vw !important;
  vertical-align: top;
}
.spcae1 {
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
}
.space2 {
  margin-top: 150px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}
.spcae3 {
  padding-bottom: 50px;
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
}
.fixed-back {
  position: relative;
  // min-height: 100%;
  // height: 1350px; /*--スクロールをさせるため 高さ指定--*/
  background-size: cover; /*--背景画像のサイズ--*/
  // background-attachment: fixed; /*--背景画像の固定--*/
  background-repeat: no-repeat; /*--背景画像の繰り返し--*/
  background-position: center center; /*--背景画像の位置--*/
  z-index: 2;
  padding: 5em 0;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    width: 100%;
    // background-size: auto 100%;
    // background-size:contain;
  }
}
.fixed-bottom {
  padding-bottom: 50px;
  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}
.link {
  padding-top: 50px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}
.strong {
  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }
}
</style>
